import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyCV4xYaevc2RLalEk6Vv3gI3sBBBC8PiiE",
    authDomain: "zodiactalismans.firebaseapp.com",
    databaseURL: "https://zodiactalismans-default-rtdb.firebaseio.com/",
    projectId: "zodiactalismans",
    storageBucket: "zodiactalismans.appspot.com",
    messagingSenderId: "583205836173",
    appId: "1:583205836173:web:024f213f770d0e7316d9c4",
    measurementId: "G-PDRY1FRBD7",
  };

firebase.initializeApp(firebaseConfig);

export default firebase;

export const database = firebase.database();
export const auth = firebase.auth();
// export const storage = firebase.storage();
// export const googleAuthProvider = new firebase.auth.GoogleAuthProvider();
// export const messaging = firebase.messaging();