import React from "react";
import {
  BrowserRouter as Router,
  Redirect,
  Switch,
  Route,
} from "react-router-dom";
import { ProvideAuth } from "./hooks/useAuth.js";
import { ProvideCurrentUser } from "./hooks/useCurrentUser";
import { Collection, Profile, Signin, Shop } from "./pages";
import Layout from "./components/Layout";
import PrivateRoute from "./util/PrivateRoute";
// import { useQuery } from "@apollo/react-hooks";
// import GET_TRANSFERS from "./graphql/subgraph";

export default function App() {
  return (
    <ProvideAuth>
      <ProvideCurrentUser>
        <Router>
          <Layout>
            <Switch>
              <Route path="/signin" exact>
                <Signin />
              </Route>

              <PrivateRoute path="/" exact>
                <Redirect to="/profile" />
              </PrivateRoute>
              <PrivateRoute path="/profile" exact>
                <Profile />
              </PrivateRoute>

              <PrivateRoute path="/collection" exact>
                <Collection />
              </PrivateRoute>

              <Route path="/shop" exact>
                <Shop />
              </Route>
              
            </Switch>
          </Layout>
        </Router>
      </ProvideCurrentUser>
    </ProvideAuth>
  );
}
