import React from "react";
import CookieConsent from "react-cookie-consent";

export default function CookieConsentBanner() {
    return (
        <CookieConsent
          location="bottom"
          buttonText="OK"
          // buttonWrapperClasses=""
          buttonClasses="text-lg"
          cookieName="cookie-consent"
          style={{ background: "#2B373B", textAlign: "center" }}
          expires={150}
        >
          <div className="md:text-lg text-md">
            Our site uses cookies to enhance user experience, measure site
            traffic and optimize ads. By using this website, you agree to our
            <span className="px-2">
              <a
                className="text-blue-500"
                href="https://zodiactalismans.com/privacy"
                target="_blank"
                rel="noopener noreferrer"
              >
                Privacy Policy
              </a>
            </span>
            and
            <span className="px-2">
              <a
                className="text-blue-500"
                href="https://zodiactalismans.com/terms"
                target="_blank"
                rel="noopener noreferrer"
              >
                Terms and Conditions
              </a>
              .
            </span>
          </div>
        </CookieConsent>
    )
}