import styled from "styled-components";

export { default as CookieConsentBanner } from "./CookieConsentBanner";
export { default as Identicon } from "./Identicon";
export { default as Footer } from "./Footer";
export { default as LoaderFallback } from "./LoaderFallback";
export { default as ModalTalismanInfo } from "./ModalTalismanInfo";
export { default as Navbar } from "./Navbar";
export { default as NavbarAccountDropdown } from "./NavbarAccountDropdown";
export { default as ProfileStats } from "./ProfileStats";
export { default as SpaceBackground } from "./SpaceBackground";
export { default as WalletButton } from "./WalletButton";


export const Header = styled.header`
  background-color: #282c34;
  min-height: 70px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  color: white;
`;

export const Body = styled.body`
  align-items: center;
  background-color: #282c34;
  color: white;
  display: flex;
  flex-direction: column;
  font-size: calc(10px + 2vmin);
  justify-content: center;
  min-height: calc(100vh - 70px);
`;

export const Image = styled.img`
  height: 40vmin;
  margin-bottom: 16px;
  pointer-events: none;
`;

export const ImageNav = styled.img`
  width: 50px;
  height: 50px;
  margin-bottom: 16px;
  pointer-events: none;
`;

export const ImageFooter = styled.img`
  width: 16px;
  height: 16px;
  margin-bottom: 16px;
  pointer-events: none;
`;

export const LinkExternal = styled.a.attrs({
  target: "_blank",
  rel: "noopener noreferrer",
})`
  color: #61dafb;
`;

export const LinkInternal = styled.a`
`;

export const Button = styled.button`
  background-color: white;
  border: none;
  border-radius: 8px;
  color: #282c34;
  cursor: pointer;
  font-size: 16px;
  text-align: center;
  text-decoration: none;
  margin: 0px 20px;
  padding: 12px 24px;
  transition: "all .15s ease";

  ${(props) => props.hidden && "hidden"} :focus {
    border: none;
    outline: none;
  }
`;