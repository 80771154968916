import React from "react";

export default function ModalTalismanInfo(props) {
  const [showModal, setShowModal] = React.useState(false);
  const {
    name,
    talismanId,
    description,
    maxQuantity,
    animal,
    rarity,
    saga,
    talismanStyle,
    talismanStyleId,
  } = props;
  return (
    <>
      <button
        // className="bg-gray-600 text-white active:bg-gray-700 font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150 text-lg lg:text-sm"
        className="bg-gradient-to-l from-purple-400 via-pink-500 to-red-500 
        focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500
        hover:via-red-500 via-pink-500 to-mtt-purplr-400
         text-white font-bold uppercase px-6 py-3 rounded shadow mr-1 mb-1 ease-linear transition-all duration-150 text-sm lg:text-sm"
        type="button"
        onClick={() => setShowModal(true)}
      >
        Info
      </button>
      {showModal ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-auto my-6 mx-auto max-w-sm">
              {/*content*/}
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-gray-200 outline-none focus:outline-none">
                {/*header*/}
                <div className="flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t mx-auto">
                  <div className="px-4 sm:px-6">
                    <h3 className="text-2xl leading-6 font-medium text-gray-900">
                      {name}
                    </h3>
                    <p className="mt-1 max-w-2xl text-xl text-gray-500">
                      {talismanId}
                    </p>
                  </div>

                  {/* <button
                    className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                    onClick={() => setShowModal(false)}
                  >
                    <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                      ×
                    </span>
                  </button> */}
                </div>
                {/*body*/}
                <div className="relative flex-auto">
                  {/* <p className="my-4 text-blueGray-500 text-lg leading-relaxed">
                    {description}
                  </p> */}

                  <div className="border-t border-gray-200">
                    <dl>
                      <div className="bg-gray-300 px-4 py-2 md:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-sm font-medium text-gray-500">
                          Rarity
                        </dt>
                        <dd className="text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                          {rarity}
                        </dd>
                      </div>
                      <div className="bg-gray-200 px-4 py-2 md:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-sm font-medium text-gray-500">
                          Max Quantity
                        </dt>
                        <dd className="text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                          {maxQuantity}
                        </dd>
                      </div>
                      <div className="bg-gray-300 px-4 py-2 md:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-sm font-medium text-gray-500">
                          About
                        </dt>
                        <dd className="text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                          {description}
                        </dd>
                      </div>
                      <div className="bg-gray-200 px-4 py-2 md:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-sm font-medium text-gray-500">
                          Saga
                        </dt>
                        <dd className="text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                          {saga}
                        </dd>
                      </div>
                      <div className="bg-gray-300 px-4 py-2 md:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-sm font-medium text-gray-500">
                          Animal
                        </dt>
                        <dd className="text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                          {animal}
                        </dd>
                      </div>
                      <div className="bg-gray-200 px-4 py-2 md:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-sm font-medium text-gray-500">
                          Style
                        </dt>
                        <dd className="text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                          {talismanStyle}
                        </dd>
                      </div>
                      <div className="bg-gray-300 px-4 py-2 md:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-sm font-medium text-gray-500">
                          Style ID
                        </dt>
                        <dd className="text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                          {talismanStyleId}
                        </dd>
                      </div>
                    </dl>
                  </div>
                </div>
                {/*footer*/}
                <div className="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b mx-auto">
                  {/* <button
                    className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={() => setShowModal(false)}
                  >
                    Ok
                  </button> */}
                  <button
                    className="bg-gray-800 text-white active:bg-gray-900 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={() => setShowModal(false)}
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  );
}
