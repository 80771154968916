export default function Shop() {
    
    // const text = "<p style='background: rgb(255, 255, 255); color: rgb(0, 0, 0);'>hello!</p>"
    return (
        <section className="text-gray-100 h-screen bg-gray-500">
            <h1 className="text-center text-5xl lg:text-7xl mt-10 font-semibold rainbow-text tracking-wide">Talisman Shop</h1>
            <p className="text-center text-2xl mt-8">Please read <a href="https://zodiactalismans.com/disclaimers" target="_blank" className="font-semibold underline text-green-500">Disclaimers</a> before browsing or purchasing any product in our shop.</p>

            
        
        
        </section>
    )
}