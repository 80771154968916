import React from "react";
import { withRouter } from "react-router-dom";
import { CookieConsentBanner, Footer, LoaderFallback, Navbar } from "./index";
import { useCurrentUser } from "../hooks/useCurrentUser";

function Layout(props) {
  let { children } = props;
  const currentUser = useCurrentUser();

  return (
    <div className="flex flex-col min-h-screen ">
      {/* "flex flex-col min-h-screen" is for footer sticky to bottom */}
      <Navbar />
      <main className="flex-grow bg-black">
        {/* "flex-grow" is for footer sticky to bottom */}
        {currentUser.isLoading ? <LoaderFallback /> : children}
      </main>
      <CookieConsentBanner />
      <Footer />
    </div>
  );
}

export default withRouter(Layout);
