import React, { useState, useEffect, useContext, createContext } from "react";
import firebase, { database } from "../util/firebase";
import { useAuth } from "../hooks/useAuth.js";
import { Web3ZTAL } from "../util/useWeb3ZTAL";

const currentUserContext = createContext();
// Provider component that wraps your app and makes currentUser object ...
// ... available to any child component that calls useCurrentUser()
export function ProvideCurrentUser({ children }) {
  const currentUser = useProvideCurrentUser();
  return (
    <currentUserContext.Provider value={currentUser}>
      {children}
    </currentUserContext.Provider>
  );
}
// Hook for child components to get the currentUser object ...
// ... and re-render when it changes.
export const useCurrentUser = () => {
  return useContext(currentUserContext);
};
// Provider hook that creates currentUser object and handles state
function useProvideCurrentUser() {
  // Get auth state and re-render anytime it changes
  const auth = useAuth();

  const [isLoading, setIsLoading] = useState(true);
  const [checkedUserAuthState, setCheckedUserAuthState] = useState(0); // 0 is not yet checked. 1 is done checking no user found. 2 done checking user, and user is found.
  const [user, setUser] = useState({}); // the user from firebase auth
  const [profile, setProfile] = useState(null); // the user data from firebase database
  const [stats, setStats] = useState(null); // the stats from firebase database
  const [collectionsFromDB, setCollectionsFromDB] = useState([]);
  const [zodiacTalismanIdsDB, setZodiacTalismanIdsDB] = useState([]);
  const [allTalismanIds, setAllTalismanIds] = useState(null);
  // const [newData, setNewData] = useState("");
  const [
    // numOfTalismansInEthAddress,
    talismanIdsInEthAddress,
    doneGettingTalismanIds,
    userEthAddress,
  ] = Web3ZTAL();

  /**
   * For Loader code AND get/set user in DB
   * So signed in users do not see the sign in ui
   */
  // Duplicate code from useAuth.js because we want App to keep state of checkedUserAuthState to use for updating isLoading state.
  // Subscribe to user on mount
  // Because this sets state in the callback it will cause any ...
  // ... component that utilizes this hook to re-render with the ...
  // ... latest auth object.
  useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged((user) => {
      setUser(user || {}); // the user obtained from firebase auth (NOT the database user)
      if (user) {
        setCheckedUserAuthState(2); // user is signed in
      } else {
        setCheckedUserAuthState(1); // user is not signed in
      }
    });
    // Cleanup subscription on unmount
    return () => unsubscribe();
  }, []);
  // if we are done checking for user, app is done loading. Doesn't matter if user is signed in or not.
  useEffect(() => {
    if (checkedUserAuthState === 1 || checkedUserAuthState === 2) {
      if (auth.user) {
        let userRef = database.ref("/users/" + user.uid); // the user.uid is obtained from firebase auth (NOT the database user)
        userRef.on("value", (snapshot) => {
          const data = snapshot.val(); // the user data from firebase database
          setProfile(data);
        });

        // Add user to users database if not exist
        userRef.once("value", (snapshot) => {
          const userData = snapshot.val();
          if (!userData) {
            userRef.set({
              name: user.displayName,
              email: user.email,
            });
          }
        });
      } else {
        setProfile(null);
        setStats(null);
      }
      setIsLoading(false);
    }
  }, [checkedUserAuthState, auth.user, user]);

  useEffect(() => {
    if (profile) {
      // Stats from DB
      let statsRef = database.ref("/stats/" + user.uid); // the user.uid is obtained from firebase auth (NOT the database user)
      statsRef.on("value", (snapshot) => {
        const statsData = snapshot.val(); // stats from firebase database
        setStats(statsData);
      });
      // statsRef.once("value", (snapshot) => {
      //   // Add stats to database if not exist
      //   const statsData = snapshot.val();
      //   if (!statsData) {
      //     statsRef.set({
      //       zodiacTalismans: numOfTalismansInEthAddress,
      //       // setsCompleted: 3,
      //       // beltsCollected: 2,
      //     });
      //   }
      // });
    }

    // Collections from DB
    let collectionsRef = database.ref("/collections/" + user.uid); // the user.uid is obtained from firebase auth (NOT the database user)
    collectionsRef.on("value", (snapshot) => {
      const collectionsData = snapshot.val(); // collections from firebase database
      setCollectionsFromDB(collectionsData);
    });
  }, [profile, user]);

  // set the zodiacTalimans ids array from DB
  useEffect(() => {
    if (collectionsFromDB) {
      setZodiacTalismanIdsDB(collectionsFromDB.zodiacTalismans);
    }
  }, [collectionsFromDB]);

  // for display ONLY. Does NOT and should NOT persist to database.
  useEffect(() => {
    // if talisman ids found in connected Ethereum address
    if (talismanIdsInEthAddress && collectionsFromDB.zodiacTalismans) {
      // combine both talisman ids arrays
      let talismansIdsCombined = talismanIdsInEthAddress.concat(
        collectionsFromDB
      );
      let talismansIdsCombinedNoDuplicates = [...new Set(talismansIdsCombined)]; // remove duplicates
      let talismansIdsCombinedNoDuplicatesSorted = talismansIdsCombinedNoDuplicates.sort(); // sort it
      setAllTalismanIds(talismansIdsCombinedNoDuplicatesSorted);
    } else {
      setAllTalismanIds(zodiacTalismanIdsDB);
    }
  }, [talismanIdsInEthAddress, zodiacTalismanIdsDB, doneGettingTalismanIds, collectionsFromDB]);

  useEffect(() => {
    if (userEthAddress && auth.user) {
      let ethWalletAddressesRef = database.ref(
        "/ethWalletAddresses/" + user.uid
      ); // the user.uid is obtained from firebase auth

      // Add ETH address data to database if not exist
      ethWalletAddressesRef.once("value", (snapshot) => {
        let date = new Date(Date.now()); // i.e., Fri May 21 2021 10:57:19 GMT-0400 (Eastern Daylight Time)
        date = String(date);

        let userData = snapshot.val(); // the user's ETH address data from firebase database

        // if there is no ethWalletAddress data at all for user.
        if (!userData) {
          ethWalletAddressesRef.set({
            ethAddresses: [{ ethAddress: userEthAddress, lastConnected: date }],
            email: user.email,
          });
        }

        // if the connected userEthAddress is not in database, add it to db
        let checkForUserEthAddressRef = ethWalletAddressesRef
          .child("ethAddresses")
          .orderByChild("ethAddress")
          .equalTo(userEthAddress);

        checkForUserEthAddressRef.once("value").then(function(snapshot) {
          let userEthAddressInDB = snapshot.val();
          // if userEthAddressInDB is null 
          if (!userEthAddressInDB) {
            // push new EthAddress data into the original ethAddresses array from db
            userData.ethAddresses.push({
              ethAddress: userEthAddress,
              lastConnected: date,
            });
            // update in db
            const update = userData.ethAddresses;
            ethWalletAddressesRef.child("ethAddresses").update(update);
          }
        });
      });
    }
  }, [userEthAddress, auth.user, user]);

  return {
    isLoading,
    checkedUserAuthState,
    user,
    profile,
    stats,
    collectionsFromDB,
    allTalismanIds,
  };
}
