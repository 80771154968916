import React from "react";
import { Popover } from "@headlessui/react";
import { useLocation } from "react-router-dom";
import { getTalisman, convertToStyleId } from "../../data/talismans";
import {
  ModalTalismanInfo,
  ProfileStats,
  SpaceBackground,
  WalletButton,
} from "../../components";
import { Web3ZTAL } from "../../util/useWeb3ZTAL";
import { useCurrentUser } from "../../hooks/useCurrentUser";
import { Web3OCCMSTORE } from "../../util/useWeb3OCCMSTORE";

const navigation = [
  { name: "Profile", href: "/profile", current: false },
  { name: "My Collection", href: "/collection", current: false },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function Loader() {
  return (
    <div className="text-center text-white">
      <p>Loading Zodiac Talismans ...</p>
    </div>
  );
}

function TalismanListing({ talismanId, talismanJSON }) {
  return (
    <div
      className="text-xl font-bold block uppercase text-gray-100 border-solid border-opacity-30 border-2 border-gray-300 rounded-3xl py-5 px-5 mx-auto text-center"
      key={talismanId}
    >
      <img
        src={talismanJSON.image}
        alt={talismanJSON.name}
        style={{ maxWidth: "200px" }}
      />
      <h3 className="mt-5 text-2xl">{talismanJSON.attributes.animal}</h3>
      <p className="mb-5 text-xl">{talismanId}</p>
      <ModalTalismanInfo
        name={talismanJSON.name}
        talismanId={talismanId}
        description={talismanJSON.description}
        maxQuantity={talismanJSON.attributes.max_quantity}
        animal={talismanJSON.attributes.animal}
        rarity={talismanJSON.attributes.rarity}
        saga={talismanJSON.attributes.saga}
        talismanStyle={talismanJSON.attributes.talisman_style}
        talismanStyleId={talismanJSON.attributes.talisman_style_id}
      />
    </div>
  );
}

export default function Collection() {
  const currentUser = useCurrentUser();
  const [numOfTalismansInEthAddress, doneGettingTalismanIds] = Web3ZTAL();
  const [
    numOfWhiteBeltsInEthAddress,
    whiteBeltIdsInEthAddress,
  ] = Web3OCCMSTORE();

  function getTalismanJSON(talismanId) {
    // get the json data for talisman style
    let talisman_json = getTalisman(convertToStyleId(talismanId));

    return talisman_json;
  }

  let location = useLocation();
  let currentURL = location.pathname;

  if (numOfWhiteBeltsInEthAddress) {
    console.log(
      "/collection numOfWhiteBeltsInEthAddress ",
      numOfWhiteBeltsInEthAddress
    );
  }

  if (whiteBeltIdsInEthAddress) {
    console.log(
      "/collection whiteBeltIdsInEthAddress ",
      whiteBeltIdsInEthAddress
    );
  }

  return (
    <div>
      <div className="bg-black">
        <div className="relative z-index-one">
          <div>
            {/* Need two show SpaceBackground before and after talismans load. Fixes star overstretching. */}
            {!doneGettingTalismanIds && <SpaceBackground />}
            {doneGettingTalismanIds && <SpaceBackground />}

            <div>
              <Popover as="header" className="pb-24">
                {() => (
                  <>
                    <div className="max-w-3xl mx-auto px-4 sm:px-6 lg:max-w-7xl lg:px-8">
                      <div className="relative flex flex-wrap items-center justify-center lg:justify-between">
                        <div className="w-full py-5 lg:border-t lg:border-white lg:border-opacity-20">
                          <div className="lg:grid lg:items-center">
                            <div className="lg:block lg:col-span-2">
                              <nav className="flex space-x-4">
                                {navigation.map((item) => (
                                  <a
                                    key={item.name}
                                    href={item.href}
                                    className={classNames(
                                      item.href === currentURL
                                        ? "text-gray-900 bg-opacity-90 font-semibold border-solid border-mtt-teal border-2 bg-gradient-to-r from-gh-text-brightcyan to-gh-text-lightpurple ring-2 ring-offset-2 ring-indigo-500"
                                        : "text-gray-100 font-medium bg-gradient-to-r from-seablue-left to-seablue-right border-2 border-gh-bg-darkerblue hover:border-mtt-teal hover:border-solid",
                                      "text-white text-sm rounded-md bg-white bg-opacity-0 px-3 py-2 hover:bg-opacity-80"
                                    )}
                                    aria-current={
                                      item.current ? "page" : undefined
                                    }
                                  >
                                    {item.name}
                                  </a>
                                ))}
                              </nav>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </Popover>

              <div className="-mt-24 pb-8">
                <div className="max-w-3xl mx-auto px-4 sm:px-6 lg:max-w-7xl lg:px-8">
                  <div className="grid grid-cols-1 gap-4 items-start">
                    <div className="grid grid-cols-1 gap-4 lg:col-span-2">
                      <section aria-labelledby="profile-overview-title">
                        <div className="rounded-lg overflow-hidden shadow">
                          <div className="p-6 bg-gradient-to-l from-frost-left to-frost-right">
                            <div className="sm:flex sm:items-center sm:justify-between">
                              <div className="sm:flex sm:space-x-5">
                                <div className="mt-4 text-center sm:mt-0 sm:pt-1 sm:text-left">
                                  <p className="text-xl font-bold text-gray-100 sm:text-2xl">
                                    {(currentUser.profile &&
                                      currentUser.profile.name &&
                                      currentUser.profile.name) ||
                                      (currentUser.profile &&
                                        currentUser.profile.email &&
                                        currentUser.profile.email)}
                                  </p>
                                  <p
                                    className="text-sm font-medium"
                                    style={{ color: "#7e93b0" }}
                                  >
                                    {(currentUser.profile &&
                                      currentUser.profile.role) ||
                                      "Collector"}
                                  </p>
                                </div>
                              </div>
                              <div className="mt-5 flex justify-center sm:mt-0">
                                <WalletButton />
                              </div>
                            </div>
                          </div>
                          <ProfileStats />
                        </div>
                      </section>

                      {numOfTalismansInEthAddress !== 0 &&
                        !doneGettingTalismanIds && (
                          <div className="mt-10 py-10 border-t border-gray-300 text-center">
                            <Loader />
                          </div>
                        )}
                      <div className="flex flex-wrap justify-center">
                        <div className="w-full lg:w-9/12 px-4">
                          <div className="grid grid-flow-row auto-rows-max xl:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-y-12 gap-x-14 mx-10 lg:mx-0">
                            {currentUser.allTalismanIds &&
                              currentUser.allTalismanIds.map(
                                (talismanId, idx) => {
                                  let talismanJSON = getTalismanJSON(
                                    talismanId
                                  );

                                  return (
                                    <TalismanListing
                                      talismanId={talismanId}
                                      talismanJSON={talismanJSON}
                                    />
                                  );
                                }
                              )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div className="max-w-3xl mx-auto px-4 sm:px-6 lg:px-8 lg:max-w-7xl">
                  <div className="border-t border-gray-200 py-8 text-sm text-gray-200 text-center sm:text-left">
                    {/* <Link
                      to="/contact-us"
                      className="block sm:inline"
                      style={{ color: "#d5d7da" }}
                    >
                      Have Feedback?
                    </Link> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
