import React, { useState, useEffect, useContext, createContext } from "react";
import firebase from "../util/firebase";
import StyledFirebaseAuth from "react-firebaseui/StyledFirebaseAuth";

export function NotSignedInUI(props) {
  const { fromPathname } = props;
  // Configure FirebaseUI (https://github.com/firebase/firebaseui-web#configuration)
  const uiConfig = {
    // Redirect signin flow rather than popup flow.
    signInFlow: "redirect",
    // We will display Google and Facebook as auth providers.
    signInOptions: [
      {
        // Allow the user the ability to complete sign-in cross device,
        // including the mobile apps specified in the ActionCodeSettings
        // object below.
        forceSameDevice: true,
        provider: firebase.auth.EmailAuthProvider.PROVIDER_ID,
        signInMethod: firebase.auth.EmailAuthProvider.EMAIL_LINK_SIGN_IN_METHOD,
      },
    ],
    callbacks: {
      // Set to false to avoid redirects after sign-in.
      signInSuccessWithAuthResult: () => true,
    },
    signInSuccessUrl: fromPathname
  };

  return (
    <div>
      <p className="mb-8">Please sign in or sign up below</p>
      <StyledFirebaseAuth
        uiConfig={uiConfig}
        firebaseAuth={firebase.auth()}
        className="my-14"
      />
    </div>
  );
}

const authContext = createContext();
// Provider component that wraps your app and makes auth object ...
// ... available to any child component that calls useAuth().
export function ProvideAuth({ children }) {
  const auth = useProvideAuth();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}
// Hook for child components to get the auth object ...
// ... and re-render when it changes.
export const useAuth = () => {
  return useContext(authContext);
};
// Provider hook that creates auth object and handles state
function useProvideAuth() {
  const [user, setUser] = useState(null);
  const signout = () => {
    return firebase
      .auth()
      .signOut()
      .then(() => {
        setUser(false);
      });
  };
  // Subscribe to user on mount
  // Because this sets state in the callback it will cause any ...
  // ... component that utilizes this hook to re-render with the ...
  // ... latest auth object.
  useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        setUser(user);
      } else {
        setUser(false);
      }
    });
    // Cleanup subscription on unmount
    return () => unsubscribe();
  }, []);
  // Return the user object and auth methods
  return {
    user,
    signout,
  };
}
