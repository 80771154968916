import { useState, useEffect } from "react";
import useWeb3Modal from "../hooks/useWeb3Modal";
import { Contract } from "@ethersproject/contracts";
import { getDefaultProvider } from "@ethersproject/providers";
import { addresses, abis } from "@project/contracts";

//  Configure Web3, user wallets, our smart contracts

// Use the mainnet
const network = "mainnet";
const INFURA_ID = process.env.REACT_APP_INFURA_PROJECT_ID;

// Should replace with the end-user wallet, e.g. Metamask
const defaultProvider = getDefaultProvider(network, {
  infura: INFURA_ID,
});

// Create an instance of an ethers.js Contract
const occmstoreErc721Printable = new Contract( // Read more about ethers.js on https://docs.ethers.io/v5/api/contract/contract/
  addresses.occmstoreErc721Printable, // Parameters ... (<contract address>, <occmstore contract abi>, <defaultProvider>)
  abis.occmstore,
  defaultProvider
);

async function readOnChainDataBalanceOf(walletAddress) {
  const tokenBalance = await occmstoreErc721Printable.balanceOf(walletAddress); // example walletAddress: "0x4Fe4e666Be5752f1FdD210F4Ab5DE2Cc26e3E0e8"
  // console.log({ tokenBalance: tokenBalance.toString() });
  return tokenBalance.toString();
}

async function readOnChainDataTokenOfOwnerByIndex(walletAddress, index) {
  const tokenId = await occmstoreErc721Printable.tokenOfOwnerByIndex(
    walletAddress,
    index
  ); // example walletAddress: "0x4Fe4e666Be5752f1FdD210F4Ab5DE2Cc26e3E0e8" index: 0
  return tokenId.toString();
}

export function Web3OCCMSTORE() {
  // const { loading, error, data } = useQuery(GET_TRANSFERS); // this is sample ceaERC20 data
  const [provider] = useWeb3Modal();

  // App state regarding OCCMSTORE smart contract and Web3 interface
  const [
    numOfWhiteBeltsInEthAddress,
    setNumOfWhiteBeltsInEthAddress,
  ] = useState(0);
  const [whiteBeltIdsInEthAddress, setWhiteBeltIdsInEthAddress] = useState(
    null
  );
  const [doneGettingWhiteBeltIds, setDoneGettingWhiteBeltIds] = useState(false);
  const [userEthAddress, setUserEthAddress] = useState(null);

  // Calls the data. Set the userEthAddress to client state.
  useEffect(() => {
    // Get number of white belts the user has
    async function getWhiteBeltsCount() {
      try {
        // Get the number of White Belts user has
        const count = await readOnChainDataBalanceOf(
          provider.provider.selectedAddress
        );

        // Update state with the result.
        setNumOfWhiteBeltsInEthAddress(count);
      } catch (e) {
        console.log("Error: " + e);
      }
    } // end of loader code

    // Get all white belt ids owned by user
    async function getWhiteBeltIds() {
      try {
        let idArray = [];

        for (let i = 0; i < numOfWhiteBeltsInEthAddress; i++) {
          // Get the white belt id by index
          const whiteBeltId = await readOnChainDataTokenOfOwnerByIndex(
            provider.provider.selectedAddress,
            i
          );

          idArray.push(whiteBeltId);
        }
        await setDoneGettingWhiteBeltIds(true);

        setWhiteBeltIdsInEthAddress(idArray); // Update state with the result
      } catch (e) {
        console.log("Error: " + e);
      }
    }

    numOfWhiteBeltsInEthAddress === 0 && provider && getWhiteBeltsCount(); // only getWhiteBeltsCount once
    numOfWhiteBeltsInEthAddress !== 0 &&
      !doneGettingWhiteBeltIds &&
      provider &&
      getWhiteBeltIds(); // only getWhiteBeltIds once
    provider &&
      provider.provider &&
      setUserEthAddress(provider.provider.selectedAddress);
  }, [provider, doneGettingWhiteBeltIds, numOfWhiteBeltsInEthAddress]);

  return [
    numOfWhiteBeltsInEthAddress,
    whiteBeltIdsInEthAddress,
    doneGettingWhiteBeltIds,
    userEthAddress,
  ];
}
