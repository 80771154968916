import React from "react";
import { useCurrentUser } from "../hooks/useCurrentUser";

function StatsDisplay({ stats }) {
  return (
    <div
      className="border-t border-gray-200 
                              bg-gradient-to-t from-frost-left to-frost-right
                              grid grid-cols-1 divide-y divide-gray-200 sm:grid-cols-3 sm:divide-y-0 sm:divide-x"
    >
      <div
        key="Zodiac Talismans"
        className="px-6 py-5 text-sm font-medium text-center"
      >
        <span className="text-white">
          {(stats && stats.zodiacTalismans) || 0}
        </span>{" "}
        <span className="text-gray-100">Zodiac Talismans</span>
      </div>
      {stats && stats.setsCompleted ? (
        <div
          key="Sets Completed"
          className="px-6 py-5 text-sm font-medium text-center"
        >
          <span className="text-white">
            {(stats && stats.setsCompleted) || 0}
          </span>{" "}
          <span className="text-gray-100">Sets Completed</span>
        </div>
      ) : (
        ""
      )}

      {stats && stats.beltsCollected ? (
        <div
          key="Belts Collected"
          className="px-6 py-5 text-sm font-medium text-center"
        >
          <span className="text-white">
            {(stats && stats.beltsCollected) || 0}
          </span>{" "}
          <span className="text-gray-100">Belts Collected</span>
        </div>
      ) : (
        ""
      )}
    </div>
  );
}

export default function ProfileStats() {
  const currentUser = useCurrentUser();
  return <StatsDisplay stats={currentUser.stats} />;
}
