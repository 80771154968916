// https://www.creative-tim.com/learning-lab/tailwind-starter-kit/documentation/react/navbars
// This component has auth state
import React from "react";
import { useAuth } from "../hooks/useAuth.js";
import { Link } from "react-router-dom";
import { ImageNav, LinkInternal, NavbarAccountDropdown } from "./index";
import logo from "../images/gz-logo512.png";

const MyAccountOrSignIn = (props) => {
  let { user } = props;

  if (user) {
    return <NavbarAccountDropdown userEmail={user.email} />;
  } else {
    return (
      <li className="nav-item my-auto">
        <Link to="/signin">
          <button className="inline-flex items-center px-3 py-2 border border-transparent text-md leading-4 font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 ml-6">
            Sign In
          </button>
        </Link>
      </li>
    );
  }
};

export default function Navbar(props) {
  const [navbarOpen, setNavbarOpen] = React.useState(false);

  // Get auth state and re-render anytime it changes
  const auth = useAuth();

  return (
    <>
      <nav className="relative flex flex-wrap items-center justify-between px-2 py-3 bg-black">
        <div className="container px-4 mx-auto flex flex-wrap items-center justify-between">
          <div className="w-full relative flex justify-between lg:w-auto lg:static lg:block lg:justify-start">
            <div className="text-md font-bold inline-block mr-4 py-2 whitespace-nowrap uppercase text-white align-middle">
              <LinkInternal
                href="https://zodiactalismans.com"
                title="Guardians of the Zodiacs"
              >
                <ImageNav src={logo} alt="GZ logo" />
              </LinkInternal>
            </div>
            <a
              className="text-md xl:text-xl font-bold inline-block mr-4 py-2 whitespace-nowrap uppercase text-white mt-4"
              href="https://zodiactalismans.com"
            >
              <span className="">Guardians of the Zodiacs</span>
            </a>
            <button
              className="text-white cursor-pointer text-xl leading-none px-3 py-1 border border-solid border-transparent rounded bg-transparent block lg:hidden outline-none focus:outline-none"
              type="button"
              onClick={() => setNavbarOpen(!navbarOpen)}
            >
              <i className="fas fa-bars"></i>
            </button>
          </div>
          <div
            className={
              "lg:flex flex-grow items-center" +
              (navbarOpen ? " flex" : " hidden")
            }
            id="example-navbar-danger"
          >
            <ul className="flex flex-col lg:flex-row list-none lg:ml-auto">
              <li className="nav-item my-auto">
                <LinkInternal
                  href="https://dapp.zodiactalismans.com/collection"
                  className="px-3 py-2 flex items-center text-md xl:text-xl uppercase font-bold leading-snug text-white hover:opacity-75"
                  title="Collection"
                >
                  <span className="ml-2">Collection</span>
                </LinkInternal>
              </li>
              <li className="nav-item my-auto">
                <LinkInternal
                  href="https://zodiactalismans.com/about-us"
                  className="px-3 py-2 flex items-center text-md xl:text-xl uppercase font-bold leading-snug text-white hover:opacity-75"
                  title="About us"
                >
                  <span className="ml-2">About us</span>
                </LinkInternal>
              </li>
              <li className="nav-item my-auto">
                <LinkInternal
                  href="https://zodiactalismans.com/where-to-buy"
                  className="px-3 py-2 flex items-center text-md xl:text-xl uppercase font-bold leading-snug text-white hover:opacity-75"
                  title="Where to buy"
                >
                  <span className="ml-2">Where to Buy</span>
                </LinkInternal>
              </li>
              <li className="nav-item my-auto">
                <LinkInternal
                  href="https://zodiactalismans.com/faq"
                  className="px-3 py-2 flex items-center text-md xl:text-xl uppercase font-bold leading-snug text-white hover:opacity-75"
                  title="Frequently Asked Questions"
                >
                  <span className="ml-2">FAQ</span>
                </LinkInternal>
              </li>

            {
              auth.user ? <MyAccountOrSignIn user={auth.user} /> : ""
            }
              
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
}
