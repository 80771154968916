import React from "react";
import Particles from "react-particles-js";

export default function SpaceBackground() {
  return (
      <Particles
        canvasClassName="move-to-second-to-bottom"
        height="100%"
        width="95vw"
        style={{ position: "absolute", top: "0", left: "0" }}
        params={{
          particles: {
            number: {
              value: 200,
              density: {
                enable: true,
                value_area: 1500,
              },
            },
            line_linked: {
              enable: true,
              opacity: 0.02,
            },
            move: {
              direction: "right",
              speed: 0.5, //1 0.05
            },
            size: {
              value: 1.5, // 1
            },
            opacity: {
              anim: {
                enable: true,
                speed: 1,
                opacity_min: 0.05,
              },
            },
          },
          interactivity: {
            events: {
              onclick: {
                enable: true,
                mode: "push",
              },
            },
            modes: {
              push: {
                particles_nb: 1,
              },
            },
          },
          retina_detect: true,
        }}
      />
  );
}
