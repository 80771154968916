import React from "react";
import useWeb3Modal from "../hooks/useWeb3Modal";

export default function WalletButton() {
  const [provider, loadWeb3Modal, logoutOfWeb3Modal] = useWeb3Modal();
  return (
    <button
      onClick={() => {
        if (!provider) {
          loadWeb3Modal();
        } else {
          logoutOfWeb3Modal();
        }
      }}
      // className="bg-pink-500 active:bg-pink-600 uppercase text-white font-bold hover:shadow-md shadow px-4 py-2 rounded outline-none focus:outline-none sm:mr-2 mb-1 text-sm"
      className={
        !provider
          ? " bg-gradient-to-l from-purple-400 via-pink-500 to-red-500 focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 hover:via-red-500 via-pink-500 to-mtt-purple-400 active:bg-pink-600 uppercase text-white font-bold hover:shadow-md shadow px-4 py-2 rounded outline-none focus:outline-none sm:mr-2 my-4 text-sm"
          : " bg-gray-700 active:bg-gray-500 uppercase text-white font-bold hover:shadow-md shadow px-4 py-2 rounded outline-none focus:outline-none sm:mr-2 my-4 text-sm"
      }
      type="button"
      style={{ transition: "all .15s ease" }}
    >
      {!provider ? "Connect NFT Wallet" : "Disconnect NFT Wallet"}
    </button>
  );
}
