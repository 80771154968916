/*
  This example requires Tailwind CSS v2.0+ 
  
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  const colors = require('tailwindcss/colors')
  
  module.exports = {
    // ...
    theme: {
      extend: {
        colors: {
          'light-blue': colors.lightBlue,
          teal: colors.teal,
          cyan: colors.cyan,
          rose: colors.rose,
        }
      }
    },
    plugins: [
      // ...
      require('@tailwindcss/forms'),
      require('@tailwindcss/line-clamp'),
    ]
  }
  ```
*/

import React from "react";
import { Popover } from "@headlessui/react";
import { useLocation } from "react-router-dom";
import { ProfileStats, SpaceBackground, WalletButton } from "../../components";
import { Web3ZTAL } from "../../util/useWeb3ZTAL";
import { useCurrentUser } from "../../hooks/useCurrentUser";

const navigation = [
  { name: "Profile", href: "/profile", current: false },
  { name: "My Collection", href: "/collection", current: false },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Profile() {
  const currentUser = useCurrentUser();
  const [doneGettingTalismanIds] = Web3ZTAL();

  let location = useLocation();
  let currentURL = location.pathname;

  return (
    <div>
      <div className="bg-black">
        <div className="relative z-index-one">
          <div>
            {/* Need two show SpaceBackground before and after talismans load. Fixes star overstretching. */}
            {!doneGettingTalismanIds && <SpaceBackground />}
            {doneGettingTalismanIds && <SpaceBackground />}

            <div>
              <Popover as="header" className="pb-24">
                {() => (
                  <>
                    <div className="max-w-3xl mx-auto px-4 sm:px-6 lg:max-w-7xl lg:px-8">
                      <div className="relative flex flex-wrap items-center justify-center lg:justify-between">
                        <div className="w-full py-5 lg:border-t lg:border-white lg:border-opacity-20">
                          <div className="lg:grid lg:items-center">
                            <div className="lg:block lg:col-span-2">
                              <nav className="flex space-x-4">
                                {navigation.map((item) => (
                                  <a
                                    key={item.name}
                                    href={item.href}
                                    className={classNames(
                                      item.href === currentURL
                                        ? "text-gray-900 bg-opacity-90 font-semibold border-solid border-mtt-teal border-2 bg-gradient-to-r from-gh-text-brightcyan to-gh-text-lightpurple ring-2 ring-offset-2 ring-indigo-500"
                                        : "text-gray-100 font-medium bg-gradient-to-r from-seablue-left to-seablue-right border-2 border-gh-bg-darkerblue hover:border-mtt-teal hover:border-solid",
                                      "text-white text-sm rounded-md bg-white bg-opacity-0 px-3 py-2 hover:bg-opacity-80"
                                    )}
                                    aria-current={
                                      item.current ? "page" : undefined
                                    }
                                  >
                                    {item.name}
                                  </a>
                                ))}
                              </nav>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </Popover>

              <div className="-mt-24 pb-8">
                <div className="max-w-3xl mx-auto px-4 sm:px-6 lg:max-w-7xl lg:px-8">
                  <div className="grid grid-cols-1 gap-4 items-start">
                    <div className="grid grid-cols-1 gap-4 lg:col-span-2">
                      <section aria-labelledby="profile-overview-title">
                        <div className="rounded-lg overflow-hidden shadow">
                          <div className="p-6 bg-gradient-to-l from-frost-left to-frost-right">
                            <div className="sm:flex sm:items-center sm:justify-between">
                              <div className="sm:flex sm:space-x-5">
                                <div className="mt-4 text-center sm:mt-0 sm:pt-1 sm:text-left">
                                  <p className="text-xl font-bold text-gray-100 sm:text-2xl">
                                    {(currentUser.profile &&
                                      currentUser.profile.name &&
                                      currentUser.profile.name) ||
                                      (currentUser.profile &&
                                        currentUser.profile.email &&
                                        currentUser.profile.email)}
                                  </p>
                                  <p
                                    className="text-sm font-medium"
                                    style={{ color: "#7e93b0" }}
                                  >
                                    {(currentUser.profile &&
                                      currentUser.profile.role) ||
                                      "Collector"}
                                  </p>
                                </div>
                              </div>
                              <div className="mt-5 flex justify-center sm:mt-0">
                                {/* <a
                                  href="#"
                                  className="flex justify-center items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50
                                  bg-gradient-to-r from-gh-text-lightcyan via-gh-text-blue to-gh-text-blue
                                  hover:via-gh-text-lightcyan via-gh-text-blue to-gh-text-blue
                                  focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 focus:via-gh-text-lightcyan via-gh-text-blue to-gh-text-blue
                                  "
                                >
                                  Connect NFT WAllet
                                </a> */}
                                <WalletButton />
                              </div>
                            </div>
                          </div>
                          <ProfileStats />
                        </div>
                      </section>

                      <div
                        className="shadow overflow-hidden sm:rounded-lg
                      bg-gradient-to-b from-frost-left to-frost-right
                      "
                      >
                        <div className="px-4 py-5 sm:px-6">
                          <h3 className="text-lg leading-6 font-medium text-gray-100">
                            Basic info
                          </h3>
                          {currentUser.profile && currentUser.profile.name && (
                            <p className="mt-1 max-w-2xl text-sm text-gray-100">
                              About {currentUser.profile.name}
                            </p>
                          )}
                        </div>
                        <div className="border-t border-gray-200 px-4 py-5 sm:p-0">
                          <dl className="sm:divide-y sm:divide-gray-200">
                            <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                              <dt className="text-sm font-medium text-gray-100">
                                Display name
                              </dt>
                              <dd className="mt-1 text-sm text-gray-100 sm:mt-0 sm:col-span-2">
                                {currentUser.profile &&
                                  currentUser.profile.name}
                                {/* {currentUser.profile && currentUser.profile.name ? currentUser.profile.name : <p>no display name</p>} */}
                              </dd>
                            </div>
                            <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                              <dt className="text-sm font-medium text-gray-100">
                                Email address
                              </dt>
                              <dd className="mt-1 text-sm text-gray-100 sm:mt-0 sm:col-span-2">
                                {currentUser.profile &&
                                  currentUser.profile.email}
                              </dd>
                            </div>
                          </dl>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div className="max-w-3xl mx-auto px-4 sm:px-6 lg:px-8 lg:max-w-7xl">
                  <div className="border-t border-gray-200 py-8 text-sm text-gray-200 text-center sm:text-left">
                    {/* <Link
                      to="/contact-us"
                      className="block sm:inline"
                      style={{ color: "#d5d7da" }}
                    >
                      Give Feedback
                    </Link> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
