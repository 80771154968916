let talismans = [
  {
    name: "Zodiac Talismans",
    description: "Complete Set of 12",
    attributes: {
      animals:
        "Rat, Ox, Tiger, Rabbit, Dragon, Snake, Horse, Sheep, Monkey, Rooster, Dog, Pig",
      talisman_style_id: 0,
      talisman_style: "Shale Grey",
      saga: "Chapter 0",
      rarity: "Common",
    },
  },
  {
    name: "Rat Talisman",
    description: "Humble Beginning",
    image: "https://zodiactalismans.com/images/Chinese-Rat-Shale-Grey-640.png",
    attributes: {
      animal: "Rat",
      talisman_style_id: 1010,
      talisman_style: "Shale Grey",
      saga: "Chapter 0",
      rarity: "Common",
      max_quantity: 4096,
    },
  },
  {
    name: "Ox Talisman",
    description: "Humble Beginning",
    image: "https://zodiactalismans.com/images/Chinese-Ox-Shale-Grey-640.png",
    attributes: {
      animal: "Ox",
      talisman_style_id: 1011,
      talisman_style: "Shale Grey",
      saga: "Chapter 0",
      rarity: "Common",
      max_quantity: 4096,
    },
  },
  {
    name: "Tiger Talisman",
    description: "Humble Beginning",
    image:
      "https://zodiactalismans.com/images/Chinese-Tiger-Shale-Grey-640.png",
    attributes: {
      animal: "Tiger",
      talisman_style_id: 1012,
      talisman_style: "Shale Grey",
      saga: "Chapter 0",
      rarity: "Common",
      max_quantity: 4096,
    },
  },
  {
    name: "Rabbit Talisman",
    description: "Humble Beginning",
    image:
      "https://zodiactalismans.com/images/Chinese-Rabbit-Shale-Grey-640.png",
    attributes: {
      animal: "Rabbit",
      talisman_style_id: 1013,
      talisman_style: "Shale Grey",
      saga: "Chapter 0",
      rarity: "Common",
      max_quantity: 4096,
    },
  },
  {
    name: "Dragon Talisman",
    description: "Humble Beginning",
    image:
      "https://zodiactalismans.com/images/Chinese-Dragon-Shale-Grey-640.png",
    attributes: {
      animal: "Dragon",
      talisman_style_id: 1014,
      talisman_style: "Shale Grey",
      saga: "Chapter 0",
      rarity: "Common",
      max_quantity: 4096,
    },
  },
  {
    name: "Snake Talisman",
    description: "Humble Beginning",
    image:
      "https://zodiactalismans.com/images/Chinese-Snake-Shale-Grey-640.png",
    attributes: {
      animal: "Snake",
      talisman_style_id: 1015,
      talisman_style: "Shale Grey",
      saga: "Chapter 0",
      rarity: "Common",
      max_quantity: 4096,
    },
  },
  {
    name: "Horse Talisman",
    description: "Humble Beginning",
    image:
      "https://zodiactalismans.com/images/Chinese-Horse-Shale-Grey-640.png",
    attributes: {
      animal: "Horse",
      talisman_style_id: 1016,
      talisman_style: "Shale Grey",
      saga: "Chapter 0",
      rarity: "Common",
      max_quantity: 4096,
    },
  },
  {
    name: "Sheep Talisman",
    description: "Humble Beginning",
    image:
      "https://zodiactalismans.com/images/Chinese-Sheep-Shale-Grey-640.png",
    attributes: {
      animal: "Sheep",
      talisman_style_id: 1017,
      talisman_style: "Shale Grey",
      saga: "Chapter 0",
      rarity: "Common",
      max_quantity: 4096,
    },
  },
  {
    name: "Monkey Talisman",
    description: "Humble Beginning",
    image:
      "https://zodiactalismans.com/images/Chinese-Monkey-Shale-Grey-640.png",
    attributes: {
      animal: "Monkey",
      talisman_style_id: 1018,
      talisman_style: "Shale Grey",
      saga: "Chapter 0",
      rarity: "Common",
      max_quantity: 4096,
    },
  },
  {
    name: "Rooster Talisman",
    description: "Humble Beginning",
    image:
      "https://zodiactalismans.com/images/Chinese-Rooster-Shale-Grey-640.png",
    attributes: {
      animal: "Rooster",
      talisman_style_id: 1019,
      talisman_style: "Shale Grey",
      saga: "Chapter 0",
      rarity: "Common",
      max_quantity: 4096,
    },
  },
  {
    name: "Dog Talisman",
    description: "Humble Beginning",
    image: "https://zodiactalismans.com/images/Chinese-Dog-Shale-Grey-640.png",
    attributes: {
      animal: "Dog",
      talisman_style_id: 1020,
      talisman_style: "Shale Grey",
      saga: "Chapter 0",
      rarity: "Common",
      max_quantity: 4096,
    },
  },
  {
    name: "Pig Talisman",
    description: "Humble Beginning",
    image: "https://zodiactalismans.com/images/Chinese-Pig-Shale-Grey-640.png",
    attributes: {
      animal: "Pig",
      talisman_style_id: 1021,
      talisman_style: "Shale Grey",
      saga: "Chapter 0",
      rarity: "Common",
      max_quantity: 4096,
    },
  },
  {
    name: "Rat Talisman",
    description: "Purely Relaxed",
    image: "https://zodiactalismans.com/images/Chinese-Rat-Snow-White-640.png",
    attributes: {
      animal: "Rat",
      talisman_style_id: 1110,
      talisman_style: "Snow White",
      saga: "Chapter 0",
      rarity: "Uncommon",
      max_quantity: 2048,
    },
  },
  {
    name: "Ox Talisman",
    description: "Purely Relaxed",
    image: "https://zodiactalismans.com/images/Chinese-Ox-Snow-White-640.png",
    attributes: {
      animal: "Ox",
      talisman_style_id: 1111,
      talisman_style: "Snow White",
      saga: "Chapter 0",
      rarity: "Uncommon",
      max_quantity: 2048,
    },
  },
  {
    name: "Tiger Talisman",
    description: "Purely Relaxed",
    image:
      "https://zodiactalismans.com/images/Chinese-Tiger-Snow-White-640.png",
    attributes: {
      animal: "Tiger",
      talisman_style_id: 1112,
      talisman_style: "Snow White",
      saga: "Chapter 0",
      rarity: "Uncommon",
      max_quantity: 2048,
    },
  },
  {
    name: "Rabbit Talisman",
    description: "Purely Relaxed",
    image:
      "https://zodiactalismans.com/images/Chinese-Rabbit-Snow-White-640.png",
    attributes: {
      animal: "Rabbit",
      talisman_style_id: 1113,
      talisman_style: "Snow White",
      saga: "Chapter 0",
      rarity: "Uncommon",
      max_quantity: 2048,
    },
  },
  {
    name: "Dragon Talisman",
    description: "Purely Relaxed",
    image:
      "https://zodiactalismans.com/images/Chinese-Dragon-Snow-White-640.png",
    attributes: {
      animal: "Dragon",
      talisman_style_id: 1114,
      talisman_style: "Snow White",
      saga: "Chapter 0",
      rarity: "Uncommon",
      max_quantity: 2048,
    },
  },
  {
    name: "Snake Talisman",
    description: "Purely Relaxed",
    image:
      "https://zodiactalismans.com/images/Chinese-Snake-Snow-White-640.png",
    attributes: {
      animal: "Snake",
      talisman_style_id: 1115,
      talisman_style: "Snow White",
      saga: "Chapter 0",
      rarity: "Uncommon",
      max_quantity: 2048,
    },
  },
  {
    name: "Horse Talisman",
    description: "Purely Relaxed",
    image:
      "https://zodiactalismans.com/images/Chinese-Horse-Snow-White-640.png",
    attributes: {
      animal: "Horse",
      talisman_style_id: 1116,
      talisman_style: "Snow White",
      saga: "Chapter 0",
      rarity: "Uncommon",
      max_quantity: 2048,
    },
  },
  {
    name: "Sheep Talisman",
    description: "Purely Relaxed",
    image:
      "https://zodiactalismans.com/images/Chinese-Sheep-Snow-White-640.png",
    attributes: {
      animal: "Sheep",
      talisman_style_id: 1117,
      talisman_style: "Snow White",
      saga: "Chapter 0",
      rarity: "Uncommon",
      max_quantity: 2048,
    },
  },
  {
    name: "Monkey Talisman",
    description: "Purely Relaxed",
    image:
      "https://zodiactalismans.com/images/Chinese-Monkey-Snow-White-640.png",
    attributes: {
      animal: "Monkey",
      talisman_style_id: 1118,
      talisman_style: "Snow White",
      saga: "Chapter 0",
      rarity: "Uncommon",
      max_quantity: 2048,
    },
  },
  {
    name: "Rooster Talisman",
    description: "Purely Relaxed",
    image:
      "https://zodiactalismans.com/images/Chinese-Rooster-Snow-White-640.png",
    attributes: {
      animal: "Rooster",
      talisman_style_id: 1119,
      talisman_style: "Snow White",
      saga: "Chapter 0",
      rarity: "Uncommon",
      max_quantity: 2048,
    },
  },
  {
    name: "Dog Talisman",
    description: "Purely Relaxed",
    image: "https://zodiactalismans.com/images/Chinese-Dog-Snow-White-640.png",
    attributes: {
      animal: "Dog",
      talisman_style_id: 1120,
      talisman_style: "Snow White",
      saga: "Chapter 0",
      rarity: "Uncommon",
      max_quantity: 2048,
    },
  },
  {
    name: "Pig Talisman",
    description: "Purely Relaxed",
    image: "https://zodiactalismans.com/images/Chinese-Pig-Snow-White-640.png",
    attributes: {
      animal: "Pig",
      talisman_style_id: 1121,
      talisman_style: "Snow White",
      saga: "Chapter 0",
      rarity: "Uncommon",
      max_quantity: 2048,
    },
  },
  {
    name: "Rat Talisman",
    description: "Deliberate Defense",
    image: "https://zodiactalismans.com/images/Chinese-Rat-Frost-Blue-640.png",
    attributes: {
      animal: "Rat",
      talisman_style_id: 1210,
      talisman_style: "Frost Blue",
      saga: "Chapter 0",
      rarity: "Very Uncommon",
      max_quantity: 1024,
    },
  },
  {
    name: "Ox Talisman",
    description: "Deliberate Defense",
    image: "https://zodiactalismans.com/images/Chinese-Ox-Frost-Blue-640.png",
    attributes: {
      animal: "Ox",
      talisman_style_id: 1211,
      talisman_style: "Frost Blue",
      saga: "Chapter 0",
      rarity: "Very Uncommon",
      max_quantity: 1024,
    },
  },
  {
    name: "Tiger Talisman",
    description: "Deliberate Defense",
    image:
      "https://zodiactalismans.com/images/Chinese-Tiger-Frost-Blue-640.png",
    attributes: {
      animal: "Tiger",
      talisman_style_id: 1212,
      talisman_style: "Frost Blue",
      saga: "Chapter 0",
      rarity: "Very Uncommon",
      max_quantity: 1024,
    },
  },
  {
    name: "Rabbit Talisman",
    description: "Deliberate Defense",
    image:
      "https://zodiactalismans.com/images/Chinese-Rabbit-Frost-Blue-640.png",
    attributes: {
      animal: "Rabbit",
      talisman_style_id: 1213,
      talisman_style: "Frost Blue",
      saga: "Chapter 0",
      rarity: "Very Uncommon",
      max_quantity: 1024,
    },
  },
  {
    name: "Dragon Talisman",
    description: "Deliberate Defense",
    image:
      "https://zodiactalismans.com/images/Chinese-Dragon-Frost-Blue-640.png",
    attributes: {
      animal: "Dragon",
      talisman_style_id: 1214,
      talisman_style: "Frost Blue",
      saga: "Chapter 0",
      rarity: "Very Uncommon",
      max_quantity: 1024,
    },
  },
  {
    name: "Snake Talisman",
    description: "Deliberate Defense",
    image:
      "https://zodiactalismans.com/images/Chinese-Snake-Frost-Blue-640.png",
    attributes: {
      animal: "Snake",
      talisman_style_id: 1215,
      talisman_style: "Frost Blue",
      saga: "Chapter 0",
      rarity: "Very Uncommon",
      max_quantity: 1024,
    },
  },
  {
    name: "Horse Talisman",
    description: "Deliberate Defense",
    image:
      "https://zodiactalismans.com/images/Chinese-Horse-Frost-Blue-640.png",
    attributes: {
      animal: "Horse",
      talisman_style_id: 1216,
      talisman_style: "Frost Blue",
      saga: "Chapter 0",
      rarity: "Very Uncommon",
      max_quantity: 1024,
    },
  },
  {
    name: "Sheep Talisman",
    description: "Deliberate Defense",
    image:
      "https://zodiactalismans.com/images/Chinese-Sheep-Frost-Blue-640.png",
    attributes: {
      animal: "Sheep",
      talisman_style_id: 1217,
      talisman_style: "Frost Blue",
      saga: "Chapter 0",
      rarity: "Very Uncommon",
      max_quantity: 1024,
    },
  },
  {
    name: "Monkey Talisman",
    description: "Deliberate Defense",
    image:
      "https://zodiactalismans.com/images/Chinese-Monkey-Frost-Blue-640.png",
    attributes: {
      animal: "Monkey",
      talisman_style_id: 1218,
      talisman_style: "Frost Blue",
      saga: "Chapter 0",
      rarity: "Very Uncommon",
      max_quantity: 1024,
    },
  },
  {
    name: "Rooster Talisman",
    description: "Deliberate Defense",
    image:
      "https://zodiactalismans.com/images/Chinese-Rooster-Frost-Blue-640.png",
    attributes: {
      animal: "Rooster",
      talisman_style_id: 1219,
      talisman_style: "Frost Blue",
      saga: "Chapter 0",
      rarity: "Very Uncommon",
      max_quantity: 1024,
    },
  },
  {
    name: "Dog Talisman",
    description: "Deliberate Defense",
    image: "https://zodiactalismans.com/images/Chinese-Dog-Frost-Blue-640.png",
    attributes: {
      animal: "Dog",
      talisman_style_id: 1220,
      talisman_style: "Frost Blue",
      saga: "Chapter 0",
      rarity: "Very Uncommon",
      max_quantity: 1024,
    },
  },
  {
    name: "Pig Talisman",
    description: "Deliberate Defense",
    image: "https://zodiactalismans.com/images/Chinese-Pig-Frost-Blue-640.png",
    attributes: {
      animal: "Pig",
      talisman_style_id: 1221,
      talisman_style: "Frost Blue",
      saga: "Chapter 0",
      rarity: "Very Uncommon",
      max_quantity: 1024,
    },
  },
  {
    name: "Rat Talisman",
    description: "Unfamiliar Familiarity",
    image:
      "https://zodiactalismans.com/images/Chinese-Rat-Imperial-Purple-640.png",
    attributes: {
      animal: "Rat",
      talisman_style_id: 1310,
      talisman_style: "Imperial Purple",
      saga: "Chapter 0",
      rarity: "Rare",
      max_quantity: 512,
    },
  },
  {
    name: "Ox Talisman",
    description: "Unfamiliar Familiarity",
    image:
      "https://zodiactalismans.com/images/Chinese-Ox-Imperial-Purple-640.png",
    attributes: {
      animal: "Ox",
      talisman_style_id: 1311,
      talisman_style: "Imperial Purple",
      saga: "Chapter 0",
      rarity: "Rare",
      max_quantity: 512,
    },
  },
  {
    name: "Tiger Talisman",
    description: "Unfamiliar Familiarity",
    image:
      "https://zodiactalismans.com/images/Chinese-Tiger-Imperial-Purple-640.png",
    attributes: {
      animal: "Tiger",
      talisman_style_id: 1312,
      talisman_style: "Imperial Purple",
      saga: "Chapter 0",
      rarity: "Rare",
      max_quantity: 512,
    },
  },
  {
    name: "Rabbit Talisman",
    description: "Unfamiliar Familiarity",
    image:
      "https://zodiactalismans.com/images/Chinese-Rabbit-Imperial-Purple-640.png",
    attributes: {
      animal: "Rabbit",
      talisman_style_id: 1313,
      talisman_style: "Imperial Purple",
      saga: "Chapter 0",
      rarity: "Rare",
      max_quantity: 512,
    },
  },
  {
    name: "Dragon Talisman",
    description: "Unfamiliar Familiarity",
    image:
      "https://zodiactalismans.com/images/Chinese-Dragon-Imperial-Purple-640.png",
    attributes: {
      animal: "Dragon",
      talisman_style_id: 1314,
      talisman_style: "Imperial Purple",
      saga: "Chapter 0",
      rarity: "Rare",
      max_quantity: 512,
    },
  },
  {
    name: "Snake Talisman",
    description: "Unfamiliar Familiarity",
    image:
      "https://zodiactalismans.com/images/Chinese-Snake-Imperial-Purple-640.png",
    attributes: {
      animal: "Snake",
      talisman_style_id: 1315,
      talisman_style: "Imperial Purple",
      saga: "Chapter 0",
      rarity: "Rare",
      max_quantity: 512,
    },
  },
  {
    name: "Horse Talisman",
    description: "Unfamiliar Familiarity",
    image:
      "https://zodiactalismans.com/images/Chinese-Horse-Imperial-Purple-640.png",
    attributes: {
      animal: "Horse",
      talisman_style_id: 1316,
      talisman_style: "Imperial Purple",
      saga: "Chapter 0",
      rarity: "Rare",
      max_quantity: 512,
    },
  },
  {
    name: "Sheep Talisman",
    description: "Unfamiliar Familiarity",
    image:
      "https://zodiactalismans.com/images/Chinese-Sheep-Imperial-Purple-640.png",
    attributes: {
      animal: "Sheep",
      talisman_style_id: 1317,
      talisman_style: "Imperial Purple",
      saga: "Chapter 0",
      rarity: "Rare",
      max_quantity: 512,
    },
  },
  {
    name: "Monkey Talisman",
    description: "Unfamiliar Familiarity",
    image:
      "https://zodiactalismans.com/images/Chinese-Monkey-Imperial-Purple-640.png",
    attributes: {
      animal: "Monkey",
      talisman_style_id: 1318,
      talisman_style: "Imperial Purple",
      saga: "Chapter 0",
      rarity: "Rare",
      max_quantity: 512,
    },
  },
  {
    name: "Rooster Talisman",
    description: "Unfamiliar Familiarity",
    image:
      "https://zodiactalismans.com/images/Chinese-Rooster-Imperial-Purple-640.png",
    attributes: {
      animal: "Rooster",
      talisman_style_id: 1319,
      talisman_style: "Imperial Purple",
      saga: "Chapter 0",
      rarity: "Rare",
      max_quantity: 512,
    },
  },
  {
    name: "Dog Talisman",
    description: "Unfamiliar Familiarity",
    image:
      "https://zodiactalismans.com/images/Chinese-Dog-Imperial-Purple-640.png",
    attributes: {
      animal: "Dog",
      talisman_style_id: 1320,
      talisman_style: "Imperial Purple",
      saga: "Chapter 0",
      rarity: "Rare",
      max_quantity: 512,
    },
  },
  {
    name: "Pig Talisman",
    description: "Unfamiliar Familiarity",
    image:
      "https://zodiactalismans.com/images/Chinese-Pig-Imperial-Purple-640.png",
    attributes: {
      animal: "Pig",
      talisman_style_id: 1321,
      talisman_style: "Imperial Purple",
      saga: "Chapter 0",
      rarity: "Rare",
      max_quantity: 512,
    },
  },
  {
    name: "Rat Talisman",
    description: "Creative Expression",
    image: "https://zodiactalismans.com/images/Chinese-Rat-Amber-Brown-640.png",
    attributes: {
      animal: "Rat",
      talisman_style_id: 1410,
      talisman_style: "Amber Brown",
      saga: "Chapter 0",
      rarity: "Very Rare",
      max_quantity: 256,
    },
  },
  {
    name: "Ox Talisman",
    description: "Creative Expression",
    image: "https://zodiactalismans.com/images/Chinese-Ox-Amber-Brown-640.png",
    attributes: {
      animal: "Ox",
      talisman_style_id: 1411,
      talisman_style: "Amber Brown",
      saga: "Chapter 0",
      rarity: "Very Rare",
      max_quantity: 256,
    },
  },
  {
    name: "Tiger Talisman",
    description: "Creative Expression",
    image:
      "https://zodiactalismans.com/images/Chinese-Tiger-Amber-Brown-640.png",
    attributes: {
      animal: "Tiger",
      talisman_style_id: 1412,
      talisman_style: "Amber Brown",
      saga: "Chapter 0",
      rarity: "Very Rare",
      max_quantity: 256,
    },
  },
  {
    name: "Rabbit Talisman",
    description: "Creative Expression",
    image:
      "https://zodiactalismans.com/images/Chinese-Rabbit-Amber-Brown-640.png",
    attributes: {
      animal: "Rabbit",
      talisman_style_id: 1413,
      talisman_style: "Amber Brown",
      saga: "Chapter 0",
      rarity: "Very Rare",
      max_quantity: 256,
    },
  },
  {
    name: "Dragon Talisman",
    description: "Creative Expression",
    image:
      "https://zodiactalismans.com/images/Chinese-Dragon-Amber-Brown-640.png",
    attributes: {
      animal: "Dragon",
      talisman_style_id: 1414,
      talisman_style: "Amber Brown",
      saga: "Chapter 0",
      rarity: "Very Rare",
      max_quantity: 256,
    },
  },
  {
    name: "Snake Talisman",
    description: "Creative Expression",
    image:
      "https://zodiactalismans.com/images/Chinese-Snake-Amber-Brown-640.png",
    attributes: {
      animal: "Snake",
      talisman_style_id: 1415,
      talisman_style: "Amber Brown",
      saga: "Chapter 0",
      rarity: "Very Rare",
      max_quantity: 256,
    },
  },
  {
    name: "Horse Talisman",
    description: "Creative Expression",
    image:
      "https://zodiactalismans.com/images/Chinese-Horse-Amber-Brown-640.png",
    attributes: {
      animal: "Horse",
      talisman_style_id: 1416,
      talisman_style: "Amber Brown",
      saga: "Chapter 0",
      rarity: "Very Rare",
      max_quantity: 256,
    },
  },
  {
    name: "Sheep Talisman",
    description: "Creative Expression",
    image:
      "https://zodiactalismans.com/images/Chinese-Sheep-Amber-Brown-640.png",
    attributes: {
      animal: "Sheep",
      talisman_style_id: 1417,
      talisman_style: "Amber Brown",
      saga: "Chapter 0",
      rarity: "Very Rare",
      max_quantity: 256,
    },
  },
  {
    name: "Monkey Talisman",
    description: "Creative Expression",
    image:
      "https://zodiactalismans.com/images/Chinese-Monkey-Amber-Brown-640.png",
    attributes: {
      animal: "Monkey",
      talisman_style_id: 1418,
      talisman_style: "Amber Brown",
      saga: "Chapter 0",
      rarity: "Very Rare",
      max_quantity: 256,
    },
  },
  {
    name: "Rooster Talisman",
    description: "Creative Expression",
    image:
      "https://zodiactalismans.com/images/Chinese-Rooster-Amber-Brown-640.png",
    attributes: {
      animal: "Rooster",
      talisman_style_id: 1419,
      talisman_style: "Amber Brown",
      saga: "Chapter 0",
      rarity: "Very Rare",
      max_quantity: 256,
    },
  },
  {
    name: "Dog Talisman",
    description: "Creative Expression",
    image: "https://zodiactalismans.com/images/Chinese-Dog-Amber-Brown-640.png",
    attributes: {
      animal: "Dog",
      talisman_style_id: 1420,
      talisman_style: "Amber Brown",
      saga: "Chapter 0",
      rarity: "Very Rare",
      max_quantity: 256,
    },
  },
  {
    name: "Pig Talisman",
    description: "Creative Expression",
    image: "https://zodiactalismans.com/images/Chinese-Pig-Amber-Brown-640.png",
    attributes: {
      animal: "Pig",
      talisman_style_id: 1421,
      talisman_style: "Amber Brown",
      saga: "Chapter 0",
      rarity: "Very Rare",
      max_quantity: 256,
    },
  },
  {
    name: "Rat Talisman",
    description: "Humble Reflection",
    image:
      "https://zodiactalismans.com/images/Chinese-Rat-Obsidian-Black-640.png",
    attributes: {
      animal: "Rat",
      talisman_style_id: 1510,
      talisman_style: "Obsidian Black",
      saga: "Chapter 0",
      rarity: "Super Rare",
      max_quantity: 128,
    },
  },
  {
    name: "Ox Talisman",
    description: "Humble Reflection",
    image:
      "https://zodiactalismans.com/images/Chinese-Ox-Obsidian-Black-640.png",
    attributes: {
      animal: "Ox",
      talisman_style_id: 1511,
      talisman_style: "Obsidian Black",
      saga: "Chapter 0",
      rarity: "Super Rare",
      max_quantity: 128,
    },
  },
  {
    name: "Tiger Talisman",
    description: "Humble Reflection",
    image:
      "https://zodiactalismans.com/images/Chinese-Tiger-Obsidian-Black-640.png",
    attributes: {
      animal: "Tiger",
      talisman_style_id: 1512,
      talisman_style: "Obsidian Black",
      saga: "Chapter 0",
      rarity: "Super Rare",
      max_quantity: 128,
    },
  },
  {
    name: "Rabbit Talisman",
    description: "Humble Reflection",
    image:
      "https://zodiactalismans.com/images/Chinese-Rabbit-Obsidian-Black-640.png",
    attributes: {
      animal: "Rabbit",
      talisman_style_id: 1513,
      talisman_style: "Obsidian Black",
      saga: "Chapter 0",
      rarity: "Super Rare",
      max_quantity: 128,
    },
  },
  {
    name: "Dragon Talisman",
    description: "Humble Reflection",
    image:
      "https://zodiactalismans.com/images/Chinese-Dragon-Obsidian-Black-640.png",
    attributes: {
      animal: "Dragon",
      talisman_style_id: 1514,
      talisman_style: "Obsidian Black",
      saga: "Chapter 0",
      rarity: "Super Rare",
      max_quantity: 128,
    },
  },
  {
    name: "Snake Talisman",
    description: "Humble Reflection",
    image:
      "https://zodiactalismans.com/images/Chinese-Snake-Obsidian-Black-640.png",
    attributes: {
      animal: "Snake",
      talisman_style_id: 1515,
      talisman_style: "Obsidian Black",
      saga: "Chapter 0",
      rarity: "Super Rare",
      max_quantity: 128,
    },
  },
  {
    name: "Horse Talisman",
    description: "Humble Reflection",
    image:
      "https://zodiactalismans.com/images/Chinese-Horse-Obsidian-Black-640.png",
    attributes: {
      animal: "Horse",
      talisman_style_id: 1516,
      talisman_style: "Obsidian Black",
      saga: "Chapter 0",
      rarity: "Super Rare",
      max_quantity: 128,
    },
  },
  {
    name: "Sheep Talisman",
    description: "Humble Reflection",
    image:
      "https://zodiactalismans.com/images/Chinese-Sheep-Obsidian-Black-640.png",
    attributes: {
      animal: "Sheep",
      talisman_style_id: 1517,
      talisman_style: "Obsidian Black",
      saga: "Chapter 0",
      rarity: "Super Rare",
      max_quantity: 128,
    },
  },
  {
    name: "Monkey Talisman",
    description: "Humble Reflection",
    image:
      "https://zodiactalismans.com/images/Chinese-Monkey-Obsidian-Black-640.png",
    attributes: {
      animal: "Monkey",
      talisman_style_id: 1518,
      talisman_style: "Obsidian Black",
      saga: "Chapter 0",
      rarity: "Super Rare",
      max_quantity: 128,
    },
  },
  {
    name: "Rooster Talisman",
    description: "Humble Reflection",
    image:
      "https://zodiactalismans.com/images/Chinese-Rooster-Obsidian-Black-640.png",
    attributes: {
      animal: "Rooster",
      talisman_style_id: 1519,
      talisman_style: "Obsidian Black",
      saga: "Chapter 0",
      rarity: "Super Rare",
      max_quantity: 128,
    },
  },
  {
    name: "Dog Talisman",
    description: "Humble Reflection",
    image:
      "https://zodiactalismans.com/images/Chinese-Dog-Obsidian-Black-640.png",
    attributes: {
      animal: "Dog",
      talisman_style_id: 1520,
      talisman_style: "Obsidian Black",
      saga: "Chapter 0",
      rarity: "Super Rare",
      max_quantity: 128,
    },
  },
  {
    name: "Pig Talisman",
    description: "Humble Reflection",
    image:
      "https://zodiactalismans.com/images/Chinese-Pig-Obsidian-Black-640.png",
    attributes: {
      animal: "Pig",
      talisman_style_id: 1521,
      talisman_style: "Obsidian Black",
      saga: "Chapter 0",
      rarity: "Super Rare",
      max_quantity: 128,
    },
  },
  {
    name: "Rat Talisman",
    description: "Lovable Danger",
    image: "https://zodiactalismans.com/images/Chinese-Rat-Ruby-Red-640.png",
    attributes: {
      animal: "Rat",
      talisman_style_id: 1610,
      talisman_style: "Ruby Red",
      saga: "Chapter 0",
      rarity: "Extremely Rare",
      max_quantity: 64,
    },
  },
  {
    name: "Ox Talisman",
    description: "Lovable Danger",
    image: "https://zodiactalismans.com/images/Chinese-Ox-Ruby-Red-640.png",
    attributes: {
      animal: "Ox",
      talisman_style_id: 1611,
      talisman_style: "Ruby Red",
      saga: "Chapter 0",
      rarity: "Extremely Rare",
      max_quantity: 64,
    },
  },
  {
    name: "Tiger Talisman",
    description: "Lovable Danger",
    image: "https://zodiactalismans.com/images/Chinese-Tiger-Ruby-Red-640.png",
    attributes: {
      animal: "Tiger",
      talisman_style_id: 1612,
      talisman_style: "Ruby Red",
      saga: "Chapter 0",
      rarity: "Extremely Rare",
      max_quantity: 64,
    },
  },
  {
    name: "Rabbit Talisman",
    description: "Lovable Danger",
    image: "https://zodiactalismans.com/images/Chinese-Rabbit-Ruby-Red-640.png",
    attributes: {
      animal: "Rabbit",
      talisman_style_id: 1613,
      talisman_style: "Ruby Red",
      saga: "Chapter 0",
      rarity: "Extremely Rare",
      max_quantity: 64,
    },
  },
  {
    name: "Dragon Talisman",
    description: "Lovable Danger",
    image: "https://zodiactalismans.com/images/Chinese-Dragon-Ruby-Red-640.png",
    attributes: {
      animal: "Dragon",
      talisman_style_id: 1614,
      talisman_style: "Ruby Red",
      saga: "Chapter 0",
      rarity: "Extremely Rare",
      max_quantity: 64,
    },
  },
  {
    name: "Snake Talisman",
    description: "Lovable Danger",
    image: "https://zodiactalismans.com/images/Chinese-Snake-Ruby-Red-640.png",
    attributes: {
      animal: "Snake",
      talisman_style_id: 1615,
      talisman_style: "Ruby Red",
      saga: "Chapter 0",
      rarity: "Extremely Rare",
      max_quantity: 64,
    },
  },
  {
    name: "Horse Talisman",
    description: "Lovable Danger",
    image: "https://zodiactalismans.com/images/Chinese-Horse-Ruby-Red-640.png",
    attributes: {
      animal: "Horse",
      talisman_style_id: 1616,
      talisman_style: "Ruby Red",
      saga: "Chapter 0",
      rarity: "Extremely Rare",
      max_quantity: 64,
    },
  },
  {
    name: "Sheep Talisman",
    description: "Lovable Danger",
    image: "https://zodiactalismans.com/images/Chinese-Sheep-Ruby-Red-640.png",
    attributes: {
      animal: "Sheep",
      talisman_style_id: 1617,
      talisman_style: "Ruby Red",
      saga: "Chapter 0",
      rarity: "Extremely Rare",
      max_quantity: 64,
    },
  },
  {
    name: "Monkey Talisman",
    description: "Lovable Danger",
    image: "https://zodiactalismans.com/images/Chinese-Monkey-Ruby-Red-640.png",
    attributes: {
      animal: "Monkey",
      talisman_style_id: 1618,
      talisman_style: "Ruby Red",
      saga: "Chapter 0",
      rarity: "Extremely Rare",
      max_quantity: 64,
    },
  },
  {
    name: "Rooster Talisman",
    description: "Lovable Danger",
    image:
      "https://zodiactalismans.com/images/Chinese-Rooster-Ruby-Red-640.png",
    attributes: {
      animal: "Rooster",
      talisman_style_id: 1619,
      talisman_style: "Ruby Red",
      saga: "Chapter 0",
      rarity: "Extremely Rare",
      max_quantity: 64,
    },
  },
  {
    name: "Dog Talisman",
    description: "Lovable Danger",
    image: "https://zodiactalismans.com/images/Chinese-Dog-Ruby-Red-640.png",
    attributes: {
      animal: "Dog",
      talisman_style_id: 1620,
      talisman_style: "Ruby Red",
      saga: "Chapter 0",
      rarity: "Extremely Rare",
      max_quantity: 64,
    },
  },
  {
    name: "Pig Talisman",
    description: "Lovable Danger",
    image: "https://zodiactalismans.com/images/Chinese-Pig-Ruby-Red-640.png",
    attributes: {
      animal: "Pig",
      talisman_style_id: 1621,
      talisman_style: "Ruby Red",
      saga: "Chapter 0",
      rarity: "Extremely Rare",
      max_quantity: 64,
    },
  },
  {
    name: "Rat Talisman",
    description: "Candidly Cherished",
    image: "https://zodiactalismans.com/images/Chinese-Rat-Hot-Pink-640.png",
    attributes: {
      animal: "Rat",
      talisman_style_id: 1710,
      talisman_style: "Hot Pink",
      saga: "Chapter 0",
      rarity: "Secret Rare",
      max_quantity: 32,
    },
  },
  {
    name: "Ox Talisman",
    description: "Candidly Cherished",
    image: "https://zodiactalismans.com/images/Chinese-Ox-Hot-Pink-640.png",
    attributes: {
      animal: "Ox",
      talisman_style_id: 1711,
      talisman_style: "Hot Pink",
      saga: "Chapter 0",
      rarity: "Secret Rare",
      max_quantity: 32,
    },
  },
  {
    name: "Tiger Talisman",
    description: "Candidly Cherished",
    image: "https://zodiactalismans.com/images/Chinese-Tiger-Hot-Pink-640.png",
    attributes: {
      animal: "Tiger",
      talisman_style_id: 1712,
      talisman_style: "Hot Pink",
      saga: "Chapter 0",
      rarity: "Secret Rare",
      max_quantity: 32,
    },
  },
  {
    name: "Rabbit Talisman",
    description: "Candidly Cherished",
    image: "https://zodiactalismans.com/images/Chinese-Rabbit-Hot-Pink-640.png",
    attributes: {
      animal: "Rabbit",
      talisman_style_id: 1713,
      talisman_style: "Hot Pink",
      saga: "Chapter 0",
      rarity: "Secret Rare",
      max_quantity: 32,
    },
  },
  {
    name: "Dragon Talisman",
    description: "Candidly Cherished",
    image: "https://zodiactalismans.com/images/Chinese-Dragon-Hot-Pink-640.png",
    attributes: {
      animal: "Dragon",
      talisman_style_id: 1714,
      talisman_style: "Hot Pink",
      saga: "Chapter 0",
      rarity: "Secret Rare",
      max_quantity: 32,
    },
  },
  {
    name: "Snake Talisman",
    description: "Candidly Cherished",
    image: "https://zodiactalismans.com/images/Chinese-Snake-Hot-Pink-640.png",
    attributes: {
      animal: "Snake",
      talisman_style_id: 1715,
      talisman_style: "Hot Pink",
      saga: "Chapter 0",
      rarity: "Secret Rare",
      max_quantity: 32,
    },
  },
  {
    name: "Horse Talisman",
    description: "Candidly Cherished",
    image: "https://zodiactalismans.com/images/Chinese-Horse-Hot-Pink-640.png",
    attributes: {
      animal: "Horse",
      talisman_style_id: 1716,
      talisman_style: "Hot Pink",
      saga: "Chapter 0",
      rarity: "Secret Rare",
      max_quantity: 32,
    },
  },
  {
    name: "Sheep Talisman",
    description: "Candidly Cherished",
    image: "https://zodiactalismans.com/images/Chinese-Sheep-Hot-Pink-640.png",
    attributes: {
      animal: "Sheep",
      talisman_style_id: 1717,
      talisman_style: "Hot Pink",
      saga: "Chapter 0",
      rarity: "Secret Rare",
      max_quantity: 32,
    },
  },
  {
    name: "Monkey Talisman",
    description: "Candidly Cherished",
    image: "https://zodiactalismans.com/images/Chinese-Monkey-Hot-Pink-640.png",
    attributes: {
      animal: "Monkey",
      talisman_style_id: 1718,
      talisman_style: "Hot Pink",
      saga: "Chapter 0",
      rarity: "Secret Rare",
      max_quantity: 32,
    },
  },
  {
    name: "Rooster Talisman",
    description: "Candidly Cherished",
    image:
      "https://zodiactalismans.com/images/Chinese-Rooster-Hot-Pink-640.png",
    attributes: {
      animal: "Rooster",
      talisman_style_id: 1719,
      talisman_style: "Hot Pink",
      saga: "Chapter 0",
      rarity: "Secret Rare",
      max_quantity: 32,
    },
  },
  {
    name: "Dog Talisman",
    description: "Candidly Cherished",
    image: "https://zodiactalismans.com/images/Chinese-Dog-Hot-Pink-640.png",
    attributes: {
      animal: "Dog",
      talisman_style_id: 1720,
      talisman_style: "Hot Pink",
      saga: "Chapter 0",
      rarity: "Secret Rare",
      max_quantity: 32,
    },
  },
  {
    name: "Pig Talisman",
    description: "Candidly Cherished",
    image: "https://zodiactalismans.com/images/Chinese-Pig-Hot-Pink-640.png",
    attributes: {
      animal: "Pig",
      talisman_style_id: 1721,
      talisman_style: "Hot Pink",
      saga: "Chapter 0",
      rarity: "Secret Rare",
      max_quantity: 32,
    },
  },
  {
    name: "Rat Talisman",
    description: "Rising Challenger",
    image:
      "https://zodiactalismans.com/images/Chinese-Rat-Radiant-Yellow-640.png",
    attributes: {
      animal: "Rat",
      talisman_style_id: 1810,
      talisman_style: "Radiant Yellow",
      saga: "Chapter 0",
      rarity: "Mythic",
      max_quantity: 16,
    },
  },
  {
    name: "Ox Talisman",
    description: "Rising Challenger",
    image:
      "https://zodiactalismans.com/images/Chinese-Ox-Radiant-Yellow-640.png",
    attributes: {
      animal: "Ox",
      talisman_style_id: 1811,
      talisman_style: "Radiant Yellow",
      saga: "Chapter 0",
      rarity: "Mythic",
      max_quantity: 16,
    },
  },
  {
    name: "Tiger Talisman",
    description: "Rising Challenger",
    image:
      "https://zodiactalismans.com/images/Chinese-Tiger-Radiant-Yellow-640.png",
    attributes: {
      animal: "Tiger",
      talisman_style_id: 1812,
      talisman_style: "Radiant Yellow",
      saga: "Chapter 0",
      rarity: "Mythic",
      max_quantity: 16,
    },
  },
  {
    name: "Rabbit Talisman",
    description: "Rising Challenger",
    image:
      "https://zodiactalismans.com/images/Chinese-Rabbit-Radiant-Yellow-640.png",
    attributes: {
      animal: "Rabbit",
      talisman_style_id: 1813,
      talisman_style: "Radiant Yellow",
      saga: "Chapter 0",
      rarity: "Mythic",
      max_quantity: 16,
    },
  },
  {
    name: "Dragon Talisman",
    description: "Rising Challenger",
    image:
      "https://zodiactalismans.com/images/Chinese-Dragon-Radiant-Yellow-640.png",
    attributes: {
      animal: "Dragon",
      talisman_style_id: 1814,
      talisman_style: "Radiant Yellow",
      saga: "Chapter 0",
      rarity: "Mythic",
      max_quantity: 16,
    },
  },
  {
    name: "Snake Talisman",
    description: "Rising Challenger",
    image:
      "https://zodiactalismans.com/images/Chinese-Snake-Radiant-Yellow-640.png",
    attributes: {
      animal: "Snake",
      talisman_style_id: 1815,
      talisman_style: "Radiant Yellow",
      saga: "Chapter 0",
      rarity: "Mythic",
      max_quantity: 16,
    },
  },
  {
    name: "Horse Talisman",
    description: "Rising Challenger",
    image:
      "https://zodiactalismans.com/images/Chinese-Horse-Radiant-Yellow-640.png",
    attributes: {
      animal: "Horse",
      talisman_style_id: 1816,
      talisman_style: "Radiant Yellow",
      saga: "Chapter 0",
      rarity: "Mythic",
      max_quantity: 16,
    },
  },
  {
    name: "Sheep Talisman",
    description: "Rising Challenger",
    image:
      "https://zodiactalismans.com/images/Chinese-Sheep-Radiant-Yellow-640.png",
    attributes: {
      animal: "Sheep",
      talisman_style_id: 1817,
      talisman_style: "Radiant Yellow",
      saga: "Chapter 0",
      rarity: "Mythic",
      max_quantity: 16,
    },
  },
  {
    name: "Monkey Talisman",
    description: "Rising Challenger",
    image:
      "https://zodiactalismans.com/images/Chinese-Monkey-Radiant-Yellow-640.png",
    attributes: {
      animal: "Monkey",
      talisman_style_id: 1818,
      talisman_style: "Radiant Yellow",
      saga: "Chapter 0",
      rarity: "Mythic",
      max_quantity: 16,
    },
  },
  {
    name: "Rooster Talisman",
    description: "Rising Challenger",
    image:
      "https://zodiactalismans.com/images/Chinese-Rooster-Radiant-Yellow-640.png",
    attributes: {
      animal: "Rooster",
      talisman_style_id: 1819,
      talisman_style: "Radiant Yellow",
      saga: "Chapter 0",
      rarity: "Mythic",
      max_quantity: 16,
    },
  },
  {
    name: "Dog Talisman",
    description: "Rising Challenger",
    image:
      "https://zodiactalismans.com/images/Chinese-Dog-Radiant-Yellow-640.png",
    attributes: {
      animal: "Dog",
      talisman_style_id: 1820,
      talisman_style: "Radiant Yellow",
      saga: "Chapter 0",
      rarity: "Mythic",
      max_quantity: 16,
    },
  },
  {
    name: "Pig Talisman",
    description: "Rising Challenger",
    image:
      "https://zodiactalismans.com/images/Chinese-Pig-Radiant-Yellow-640.png",
    attributes: {
      animal: "Pig",
      talisman_style_id: 1821,
      talisman_style: "Radiant Yellow",
      saga: "Chapter 0",
      rarity: "Mythic",
      max_quantity: 16,
    },
  },
  {
    name: "Rat Talisman",
    description: "Heroic Spirit",
    image: "https://zodiactalismans.com/images/Chinese-Rat-Pure-Aqua-640.png",
    attributes: {
      animal: "Rat",
      talisman_style_id: 1910,
      talisman_style: "Pure Aqua",
      saga: "Chapter 0",
      rarity: "Secret Mythic",
      max_quantity: 8,
    },
  },
  {
    name: "Ox Talisman",
    description: "Heroic Spirit",
    image: "https://zodiactalismans.com/images/Chinese-Ox-Pure-Aqua-640.png",
    attributes: {
      animal: "Ox",
      talisman_style_id: 1911,
      talisman_style: "Pure Aqua",
      saga: "Chapter 0",
      rarity: "Secret Mythic",
      max_quantity: 8,
    },
  },
  {
    name: "Tiger Talisman",
    description: "Heroic Spirit",
    image: "https://zodiactalismans.com/images/Chinese-Tiger-Pure-Aqua-640.png",
    attributes: {
      animal: "Tiger",
      talisman_style_id: 1912,
      talisman_style: "Pure Aqua",
      saga: "Chapter 0",
      rarity: "Secret Mythic",
      max_quantity: 8,
    },
  },
  {
    name: "Rabbit Talisman",
    description: "Heroic Spirit",
    image:
      "https://zodiactalismans.com/images/Chinese-Rabbit-Pure-Aqua-640.png",
    attributes: {
      animal: "Rabbit",
      talisman_style_id: 1913,
      talisman_style: "Pure Aqua",
      saga: "Chapter 0",
      rarity: "Secret Mythic",
      max_quantity: 8,
    },
  },
  {
    name: "Dragon Talisman",
    description: "Heroic Spirit",
    image:
      "https://zodiactalismans.com/images/Chinese-Dragon-Pure-Aqua-640.png",
    attributes: {
      animal: "Dragon",
      talisman_style_id: 1914,
      talisman_style: "Pure Aqua",
      saga: "Chapter 0",
      rarity: "Secret Mythic",
      max_quantity: 8,
    },
  },
  {
    name: "Snake Talisman",
    description: "Heroic Spirit",
    image: "https://zodiactalismans.com/images/Chinese-Snake-Pure-Aqua-640.png",
    attributes: {
      animal: "Snake",
      talisman_style_id: 1915,
      talisman_style: "Pure Aqua",
      saga: "Chapter 0",
      rarity: "Secret Mythic",
      max_quantity: 8,
    },
  },
  {
    name: "Horse Talisman",
    description: "Heroic Spirit",
    image: "https://zodiactalismans.com/images/Chinese-Horse-Pure-Aqua-640.png",
    attributes: {
      animal: "Horse",
      talisman_style_id: 1916,
      talisman_style: "Pure Aqua",
      saga: "Chapter 0",
      rarity: "Secret Mythic",
      max_quantity: 8,
    },
  },
  {
    name: "Sheep Talisman",
    description: "Heroic Spirit",
    image: "https://zodiactalismans.com/images/Chinese-Sheep-Pure-Aqua-640.png",
    attributes: {
      animal: "Sheep",
      talisman_style_id: 1917,
      talisman_style: "Pure Aqua",
      saga: "Chapter 0",
      rarity: "Secret Mythic",
      max_quantity: 8,
    },
  },
  {
    name: "Monkey Talisman",
    description: "Heroic Spirit",
    image:
      "https://zodiactalismans.com/images/Chinese-Monkey-Pure-Aqua-640.png",
    attributes: {
      animal: "Monkey",
      talisman_style_id: 1918,
      talisman_style: "Pure Aqua",
      saga: "Chapter 0",
      rarity: "Secret Mythic",
      max_quantity: 8,
    },
  },
  {
    name: "Rooster Talisman",
    description: "Heroic Spirit",
    image:
      "https://zodiactalismans.com/images/Chinese-Rooster-Pure-Aqua-640.png",
    attributes: {
      animal: "Rooster",
      talisman_style_id: 1919,
      talisman_style: "Pure Aqua",
      saga: "Chapter 0",
      rarity: "Secret Mythic",
      max_quantity: 8,
    },
  },
  {
    name: "Dog Talisman",
    description: "Heroic Spirit",
    image: "https://zodiactalismans.com/images/Chinese-Dog-Pure-Aqua-640.png",
    attributes: {
      animal: "Dog",
      talisman_style_id: 1920,
      talisman_style: "Pure Aqua",
      saga: "Chapter 0",
      rarity: "Secret Mythic",
      max_quantity: 8,
    },
  },
  {
    name: "Pig Talisman",
    description: "Heroic Spirit",
    image: "https://zodiactalismans.com/images/Chinese-Pig-Pure-Aqua-640.png",
    attributes: {
      animal: "Pig",
      talisman_style_id: 1921,
      talisman_style: "Pure Aqua",
      saga: "Chapter 0",
      rarity: "Secret Mythic",
      max_quantity: 8,
    },
  },
  {
    name: "Rat Talisman",
    description: "Virtuous Excellence",
    image:
      "https://zodiactalismans.com/images/Chinese-Rat-Nature-Green-640.png",
    attributes: {
      animal: "Rat",
      talisman_style_id: 2010,
      talisman_style: "Nature Green",
      saga: "Chapter 0",
      rarity: "Legendary",
      max_quantity: 4,
    },
  },
  {
    name: "Ox Talisman",
    description: "Virtuous Excellence",
    image: "https://zodiactalismans.com/images/Chinese-Ox-Nature-Green-640.png",
    attributes: {
      animal: "Ox",
      talisman_style_id: 2011,
      talisman_style: "Nature Green",
      saga: "Chapter 0",
      rarity: "Legendary",
      max_quantity: 4,
    },
  },
  {
    name: "Tiger Talisman",
    description: "Virtuous Excellence",
    image:
      "https://zodiactalismans.com/images/Chinese-Tiger-Nature-Green-640.png",
    attributes: {
      animal: "Tiger",
      talisman_style_id: 2012,
      talisman_style: "Nature Green",
      saga: "Chapter 0",
      rarity: "Legendary",
      max_quantity: 4,
    },
  },
  {
    name: "Rabbit Talisman",
    description: "Virtuous Excellence",
    image:
      "https://zodiactalismans.com/images/Chinese-Rabbit-Nature-Green-640.png",
    attributes: {
      animal: "Rabbit",
      talisman_style_id: 2013,
      talisman_style: "Nature Green",
      saga: "Chapter 0",
      rarity: "Legendary",
      max_quantity: 4,
    },
  },
  {
    name: "Dragon Talisman",
    description: "Virtuous Excellence",
    image:
      "https://zodiactalismans.com/images/Chinese-Dragon-Nature-Green-640.png",
    attributes: {
      animal: "Dragon",
      talisman_style_id: 2014,
      talisman_style: "Nature Green",
      saga: "Chapter 0",
      rarity: "Legendary",
      max_quantity: 4,
    },
  },
  {
    name: "Snake Talisman",
    description: "Virtuous Excellence",
    image:
      "https://zodiactalismans.com/images/Chinese-Snake-Nature-Green-640.png",
    attributes: {
      animal: "Snake",
      talisman_style_id: 2015,
      talisman_style: "Nature Green",
      saga: "Chapter 0",
      rarity: "Legendary",
      max_quantity: 4,
    },
  },
  {
    name: "Horse Talisman",
    description: "Virtuous Excellence",
    image:
      "https://zodiactalismans.com/images/Chinese-Horse-Nature-Green-640.png",
    attributes: {
      animal: "Horse",
      talisman_style_id: 2016,
      talisman_style: "Nature Green",
      saga: "Chapter 0",
      rarity: "Legendary",
      max_quantity: 4,
    },
  },
  {
    name: "Sheep Talisman",
    description: "Virtuous Excellence",
    image:
      "https://zodiactalismans.com/images/Chinese-Sheep-Nature-Green-640.png",
    attributes: {
      animal: "Sheep",
      talisman_style_id: 2017,
      talisman_style: "Nature Green",
      saga: "Chapter 0",
      rarity: "Legendary",
      max_quantity: 4,
    },
  },
  {
    name: "Monkey Talisman",
    description: "Virtuous Excellence",
    image:
      "https://zodiactalismans.com/images/Chinese-Monkey-Nature-Green-640.png",
    attributes: {
      animal: "Monkey",
      talisman_style_id: 2018,
      talisman_style: "Nature Green",
      saga: "Chapter 0",
      rarity: "Legendary",
      max_quantity: 4,
    },
  },
  {
    name: "Rooster Talisman",
    description: "Virtuous Excellence",
    image:
      "https://zodiactalismans.com/images/Chinese-Rooster-Nature-Green-640.png",
    attributes: {
      animal: "Rooster",
      talisman_style_id: 2019,
      talisman_style: "Nature Green",
      saga: "Chapter 0",
      rarity: "Legendary",
      max_quantity: 4,
    },
  },
  {
    name: "Dog Talisman",
    description: "Virtuous Excellence",
    image:
      "https://zodiactalismans.com/images/Chinese-Dog-Nature-Green-640.png",
    attributes: {
      animal: "Dog",
      talisman_style_id: 2020,
      talisman_style: "Nature Green",
      saga: "Chapter 0",
      rarity: "Legendary",
      max_quantity: 4,
    },
  },
  {
    name: "Pig Talisman",
    description: "Virtuous Excellence",
    image:
      "https://zodiactalismans.com/images/Chinese-Pig-Nature-Green-640.png",
    attributes: {
      animal: "Pig",
      talisman_style_id: 2021,
      talisman_style: "Nature Green",
      saga: "Chapter 0",
      rarity: "Legendary",
      max_quantity: 4,
    },
  },
  {
    name: "Rat Talisman",
    description: "01101100 01101111 01110110 01100101",
    image:
      "https://zodiactalismans.com/images/Chinese-Rat-Celestial-Crystal-640.png",
    attributes: {
      animal: "Rat",
      talisman_style_id: 2110,
      talisman_style: "Celestial Crystal",
      saga: "Chapter 0",
      rarity: "Secret Legendary",
      max_quantity: 2,
    },
  },
  {
    name: "Ox Talisman",
    description: "01101100 01101111 01110110 01100101",
    image:
      "https://zodiactalismans.com/images/Chinese-Ox-Celestial-Crystal-640.png",
    attributes: {
      animal: "Ox",
      talisman_style_id: 2111,
      talisman_style: "Celestial Crystal",
      saga: "Chapter 0",
      rarity: "Secret Legendary",
      max_quantity: 2,
    },
  },
  {
    name: "Tiger Talisman",
    description: "01101100 01101111 01110110 01100101",
    image:
      "https://zodiactalismans.com/images/Chinese-Tiger-Celestial-Crystal-640.png",
    attributes: {
      animal: "Tiger",
      talisman_style_id: 2112,
      talisman_style: "Celestial Crystal",
      saga: "Chapter 0",
      rarity: "Secret Legendary",
      max_quantity: 2,
    },
  },
  {
    name: "Rabbit Talisman",
    description: "01101100 01101111 01110110 01100101",
    image:
      "https://zodiactalismans.com/images/Chinese-Rabbit-Celestial-Crystal-640.png",
    attributes: {
      animal: "Rabbit",
      talisman_style_id: 2113,
      talisman_style: "Celestial Crystal",
      saga: "Chapter 0",
      rarity: "Secret Legendary",
      max_quantity: 2,
    },
  },
  {
    name: "Dragon Talisman",
    description: "01101100 01101111 01110110 01100101",
    image:
      "https://zodiactalismans.com/images/Chinese-Dragon-Celestial-Crystal-640.png",
    attributes: {
      animal: "Dragon",
      talisman_style_id: 2114,
      talisman_style: "Celestial Crystal",
      saga: "Chapter 0",
      rarity: "Secret Legendary",
      max_quantity: 2,
    },
  },
  {
    name: "Snake Talisman",
    description: "01101100 01101111 01110110 01100101",
    image:
      "https://zodiactalismans.com/images/Chinese-Snake-Celestial-Crystal-640.png",
    attributes: {
      animal: "Snake",
      talisman_style_id: 2115,
      talisman_style: "Celestial Crystal",
      saga: "Chapter 0",
      rarity: "Secret Legendary",
      max_quantity: 2,
    },
  },
  {
    name: "Horse Talisman",
    description: "01101100 01101111 01110110 01100101",
    image:
      "https://zodiactalismans.com/images/Chinese-Horse-Celestial-Crystal-640.png",
    attributes: {
      animal: "Horse",
      talisman_style_id: 2116,
      talisman_style: "Celestial Crystal",
      saga: "Chapter 0",
      rarity: "Secret Legendary",
      max_quantity: 2,
    },
  },
  {
    name: "Sheep Talisman",
    description: "01101100 01101111 01110110 01100101",
    image:
      "https://zodiactalismans.com/images/Chinese-Sheep-Celestial-Crystal-640.png",
    attributes: {
      animal: "Sheep",
      talisman_style_id: 2117,
      talisman_style: "Celestial Crystal",
      saga: "Chapter 0",
      rarity: "Secret Legendary",
      max_quantity: 2,
    },
  },
  {
    name: "Monkey Talisman",
    description: "01101100 01101111 01110110 01100101",
    image:
      "https://zodiactalismans.com/images/Chinese-Monkey-Celestial-Crystal-640.png",
    attributes: {
      animal: "Monkey",
      talisman_style_id: 2118,
      talisman_style: "Celestial Crystal",
      saga: "Chapter 0",
      rarity: "Secret Legendary",
      max_quantity: 2,
    },
  },
  {
    name: "Rooster Talisman",
    description: "01101100 01101111 01110110 01100101",
    image:
      "https://zodiactalismans.com/images/Chinese-Rooster-Celestial-Crystal-640.png",
    attributes: {
      animal: "Rooster",
      talisman_style_id: 2119,
      talisman_style: "Celestial Crystal",
      saga: "Chapter 0",
      rarity: "Secret Legendary",
      max_quantity: 2,
    },
  },
  {
    name: "Dog Talisman",
    description: "01101100 01101111 01110110 01100101",
    image:
      "https://zodiactalismans.com/images/Chinese-Dog-Celestial-Crystal-640.png",
    attributes: {
      animal: "Dog",
      talisman_style_id: 2120,
      talisman_style: "Celestial Crystal",
      saga: "Chapter 0",
      rarity: "Secret Legendary",
      max_quantity: 2,
    },
  },
  {
    name: "Pig Talisman",
    description: "01101100 01101111 01110110 01100101",
    image:
      "https://zodiactalismans.com/images/Chinese-Pig-Celestial-Crystal-640.png",
    attributes: {
      animal: "Pig",
      talisman_style_id: 2121,
      talisman_style: "Celestial Crystal",
      saga: "Chapter 0",
      rarity: "Secret Legendary",
      max_quantity: 2,
    },
  },
];

// get full talisman json data
const getTalismans = () => {
  return talismans;
};

// get talisman data from json, according to talisman style id
const getTalisman = (talismanStyleId) => {
  const talismans = getTalismans();
  return talismans.find(
    (t) => t.attributes.talisman_style_id === talismanStyleId
  );
};

// convert userInput Talisman id to Talisman style id
const convertToStyleId = (userInput) => {
  return Math.floor(Number(userInput) / 10000);
};

// returns true if talisman id is within supply limit for that talisman style (example: 10100001 returns True; 10104097 returns False)
const withinSupplyLimit = (userInput) => {
  return (
    userInput % 10000 <=
    getTalisman(convertToStyleId(userInput)).attributes.max_quantity
  );
};

// make these functions available to the other files. Must import in the files that will use these functions
module.exports = {
  getTalismans,
  getTalisman,
  convertToStyleId,
  withinSupplyLimit,
};
