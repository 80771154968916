import React from "react";
import makeBlockie from "ethereum-blockies-base64";

class Identicon extends React.Component {
  render() {
    let address = this.props.address.toString();

    return (
      <img
        src={makeBlockie(address)}
        alt="identicon of ethereum address"
        className="mx-auto my-5 rounded-2xl"
      />
    );
  }
}

export default Identicon;
