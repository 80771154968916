import React from "react";
import { LinkInternal } from "./index";
// import { ImageFooter } from "./index";
import etherscanLogo from "../images/etherscan.png";
import raribleLogo from "../images/rarible-logo.png";

export default function Footer() {
  return (
    <footer className="relative bg-gradient-to-b from-gray-300 via-gray-400 to-gray-500 pt-8 pb-6">
      <div className="container mx-auto px-4">
        <div className="flex flex-wrap">
          <div className="w-full lg:w-4/12 px-4">
            <h4 className="text-3xl font-semibold">Follow us</h4>
            <h5 className="text-lg mt-0 mb-2 text-gray-700">
              Find us on any of these platforms.
            </h5>
            <div className="mt-6 mb-6">
              <a
                href="https://twitter.com/ZTalismans"
                rel="noopener noreferrer"
                target="_blank"
              >
                <button
                  className="bg-white text-blue-400 shadow-lg font-normal h-11 w-10 items-center justify-center align-center rounded-lg mr-2 p-3"
                  type="button"
                >
                  <i className="fab fa-twitter"></i>
                </button>
              </a>
              <a
                href="https://discord.gg/jDTSNbsB2A"
                rel="noopener noreferrer"
                target="_blank"
              >
                <button
                  className="bg-white text-blue-600 shadow-lg font-normal h-11 w-10 items-center justify-center align-center rounded-lg mr-2 p-3"
                  type="button"
                >
                  <i className="fab fa-discord"></i>
                </button>
              </a>
              <a href="mailto:gotz@zodiactalismans.com">
                <button
                  className="bg-white text-pink-400 shadow-lg font-normal h-11 w-10 items-center justify-center align-center rounded-lg mr-2 p-3"
                  type="button"
                >
                  <i className="fas fa-envelope text-gray-600"></i>
                </button>
              </a>
              <a
                href="https://etherscan.io/token/0x74cab08cdebf88d400f3c3b46c0bcaa7b2e8ca27"
                rel="noopener noreferrer"
                target="_blank"
              >
                <button
                  className="bg-white text-gray-900 shadow-lg font-normal h-11 w-10 items-center justify-center align-center rounded-lg mr-2 p-3"
                  type="button"
                >
                  <i>
                    <img src={etherscanLogo} alt="Etherscan" />
                  </i>
                </button>
              </a>
              {/* <a
                href="https://opensea.io/assets/zodiac-talismans"
                rel="noopener noreferrer"
                target="_blank"
              >
                <button
                  className="bg-white text-gray-900 shadow-lg font-normal h-11 w-10 items-center justify-center align-center rounded-lg mr-2 p-3"
                  type="button"
                >
                  <i>
                    <ImageFooter
                      src="/assets/img/opensea-logomark-full-colored.png"
                      alt="OpenSea"
                    />
                  </i>
                </button>
              </a> */}
            </div>
          </div>
          <div className="w-full lg:w-4/12 px-4">
            <div className="flex flex-wrap items-top mb-6">
            {/* <div className="lg:mr-16 lg:mt-14 mt-6">
                <a
                  href="https://rarible.com/zodiactalismans"
                  rel="noopener noreferrer"
                  title="Buy on Rarible"
                  target="_blank"
                >
                  <img
                    style={{
                      width: "55px",
                      height: "55px",
                      borderRadius: "5px",
                      boxShadow: "0px 1px 6px rgba(0, 0, 0, 0.25)",
                    }}
                    src={raribleLogo}
                    alt="Rarible logo"
                  />
                </a>
              </div>
              <div className="w-full lg:w-6/12 mt-14">
                <a
                  href="https://opensea.io/assets/zodiac-talismans"
                  rel="noopener noreferrer"
                  title="Buy on OpenSea"
                  target="_blank"
                >
                  <img
                    style={{
                      width: "160px",
                      borderRadius: "5px",
                      boxShadow: "0px 1px 6px rgba(0, 0, 0, 0.25)",
                    }}
                    src="https://storage.googleapis.com/opensea-static/opensea-brand/listed-button-blue.png"
                    alt="Buy on OpenSea badge"
                  />
                </a>
              </div> */}
            </div>
          </div>
          <div className="w-full lg:w-4/12 px-4">
            <div className="flex flex-wrap items-top mb-6">
              <div className="w-full lg:w-6/12 px-4 ml-auto">
                <span className="block uppercase text-gray-600 text-sm font-semibold mb-2">
                  Useful Links
                </span>
                <ul className="list-unstyled">
                  <li>
                    <LinkInternal
                      href="https://zodiactalismans.com/about-us"
                      className="text-gray-700 hover:text-gray-900 font-semibold block pb-2 text-sm"
                      title="About us"
                    >
                      About us
                    </LinkInternal>
                  </li>
                  <li>
                    <a
                      className="text-gray-700 hover:text-gray-900 font-semibold block pb-2 text-sm"
                      href="https://zodiactalismans.com/faq"
                    >
                      Common Questions
                    </a>
                  </li>
                  <li>
                    <a
                      className="text-gray-700 hover:text-gray-900 font-semibold block pb-2 text-sm"
                      href="https://zodiactalismans.com/disclaimers"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Disclaimers
                    </a>
                  </li>
                </ul>
              </div>
              <div className="w-full lg:w-6/12 px-4">
                <span className="block uppercase text-gray-600 text-sm font-semibold mb-2">
                  Other Resources
                </span>
                <ul className="list-unstyled">
                  <li>
                    <a
                      className="text-gray-700 hover:text-gray-900 font-semibold block pb-2 text-sm"
                      href="https://ethereum.org/"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      About Ethereum
                    </a>
                  </li>
                  <li>
                    <a
                      className="text-gray-700 hover:text-gray-900 font-semibold block pb-2 text-sm"
                      href="https://zodiactalismans.com/terms"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      Terms & Conditions
                    </a>
                  </li>
                  <li>
                    <a
                      className="text-gray-700 hover:text-gray-900 font-semibold block pb-2 text-sm"
                      href="https://zodiactalismans.com/privacy"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      Privacy Policy
                    </a>
                  </li>
                  <li>
                    <a
                      className="text-gray-700 hover:text-gray-900 font-semibold block pb-2 text-sm"
                      href="mailto:gotz@zodiactalismans.com"
                    >
                      Contact Us
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <hr className="my-6 border-gray-400" />
        <div className="flex flex-wrap items-center md:justify-between justify-center">
          <div className="w-full sm:w-6/12 px-4 mx-auto text-center">
            <div className="text-sm text-gray-900 font-semibold py-1">
              Copyright © {new Date().getFullYear()} Ok Cool Inc. All Rights
              Reserved.
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}
