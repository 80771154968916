// This component has auth state
import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useAuth, NotSignedInUI } from "../hooks/useAuth.js";
// import { useCurrentUser } from "../hooks/useCurrentUser";
import SpaceBackground from "../components/SpaceBackground";

function Signin() {
  // Get auth state and re-render anytime it changes
  const auth = useAuth();

  let history = useHistory();
  let location = useLocation();

  let { from } = location.state || { from: { pathname: "/" } };

  return (
    <div className="bg-black">
      <div className="relative z-index-one">
        <div className="text-center text-white">
          <SpaceBackground />

          <div style={{ height: "325px" }} />
          <div className="container mx-auto px-4">
            <div className="relative flex flex-col min-w-0 break-words bg-transparent w-full shadow-xl rounded-lg -mt-64">
              {/* <div className="px-6 mb-20 h-screen"> */}
              <div className="px-6 mb-20">
                <h1 className="text-3xl font-semibold">Sign in / Sign up</h1>

                {/* if user is signed in, redirect to previous url or home. Otherwise, show the sign in UI. https://dev.to/projectescape/programmatic-navigation-in-react-3p1l */}
                {auth.user ? (
                  history.push(from)
                ) : (
                  <NotSignedInUI fromPathname={from.pathname} />
                )}
                <p>
                  By continuing, you are indicating that you accept our {" "}
                  <a
                    className="text-blue-500 hover:text-blue-700 font-semibold"
                    href="https://zodiactalismans.com/terms"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    Terms and Conditions
                  </a>{" "}
                  and
                  {" "}<a
                    className="text-blue-500 hover:text-blue-700 font-semibold"
                    href="https://zodiactalismans.com/privacy"
                    rel="noopener noreferrer"
                    target="_blank"
                  >{" "}
                    Privacy Policy
                  </a>
                  .
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Signin;
