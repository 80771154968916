// https://www.npmjs.com/package/react-loader-spinner

import React from "react";
import styled from "styled-components";
import Loader from "react-loader-spinner";

export default function LoaderFallback() {
  //other logic
  return (
    <div className="h-screen bg-black">
      <LoaderFallbackContainer>
        <Loader
          type="Grid"
          color="#00BFFF"
          height={100}
          width={100}
          visible={true}
        />
      </LoaderFallbackContainer>
    </div>
  );
}

const LoaderFallbackContainer = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;
